/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
// import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

function Destacadas() {
  const { portal } = useParams();
  const [listData, setListData] = useState([]);
  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/news?per_page=4&portal=${portal}`;
    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        const myData = data.data;
        setListData(myData);
      });
  }, []);

  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Noticias destacadas
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          {listData.map(({ id, attributes }) => (
            <Grid key={id} item xs={12} sm={6} lg={3}>
              <TransparentBlogCard
                image={`https://wsrv.nl?url=${attributes.adjunto_large}`}
                title={attributes.title}
                description={attributes.leadin}
                action={{
                  route: `/news/post/${portal}/${id}`,
                  color: "info",
                  label: "Ir a noticia",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Destacadas;
