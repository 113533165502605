/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import React from "react";
// import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
// @mui material components
import Container from "@mui/material/Container";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

function Social({ shortDescription, name, id }) {
  const uri = `http://www.kinos.es/new-development/asset/${id}`;
  const uriBig = `\r\n\r\nVer descripción completa en: ${uri}`;
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <MKTypography
              variant="body1"
              fontWeight="light"
              color="info"
              mt={3}
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: `translateX(6px)`,
                },
              }}
              key="title"
            >
              Compartir
            </MKTypography>
            <EmailShareButton url={uriBig} subject={name} body={shortDescription} key="email">
              <EmailIcon size={32} round />
            </EmailShareButton>
            <FacebookShareButton url={uri} quote={shortDescription} hashtag="#kinos.es" key="fb">
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <LinkedinShareButton
              url={uri}
              source={uri}
              title={name}
              summary={shortDescription}
              key="lkd"
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <PinterestShareButton
              url={uri}
              media={uri}
              description={shortDescription}
              key="pinterest"
            >
              <PinterestIcon size={32} round />
            </PinterestShareButton>
            <PocketShareButton url={uri} title={name} key="pocket">
              <PocketIcon size={32} round />
            </PocketShareButton>
            <RedditShareButton url={uri} title={name} key="reddit">
              <RedditIcon size={32} round />
            </RedditShareButton>
            <TelegramShareButton url={uri} title={name} key="Telegram">
              <TelegramIcon size={32} round />
            </TelegramShareButton>
            <TumblrShareButton url={uri} title={name} key="tumblr">
              <TumblrIcon size={32} round />
            </TumblrShareButton>
            <TwitterShareButton url={uri} title={name} key="X">
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton url={uri} title={name} key="Whatsapp">
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Setting default props for the Social component
Social.defaultProps = {
  shortDescription: "",
  name: "",
  // id: null,
};

// Typechecking props for the Social component
Social.propTypes = {
  shortDescription: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default Social;
