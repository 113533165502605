/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

/**
  All of the routes are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// KinosPages
import AboutUs from "layouts/pages/company/about-us";
import Sell from "layouts/pages/company/sell";
import Buy from "layouts/pages/company/buy";
import WorkOffers from "layouts/pages/company/work-offers";
import Services from "layouts/pages/company/services";
import Demand from "layouts/pages/landing-pages/demand";
// landing-pages
import Home from "layouts/pages/landing-pages/home";
import NewDevelopment from "layouts/pages/landing-pages/new-development";
import Residential from "layouts/pages/landing-pages/residential";
import Rental from "layouts/pages/landing-pages/rental";
// import SingleArticle from "layouts/pages/blogs/single-article";
import ResidentialNews from "layouts/pages/blogs/residential-news";
import NewDevelopmentNews from "layouts/pages/blogs/newdevelopment-news";
import LifeStyleNews from "layouts/pages/blogs/lifestyle-news";

// import Author from "layouts/pages/blogs/author";
import Privacy from "layouts/pages/support/privacy";
import Legal from "layouts/pages/support/legal";
import Faq from "layouts/pages/support/faq";

// Pages

// import Pricing from "layouts/pages/company/pricing";
import HelpCenter from "layouts/pages/support/help-center";
import ContactUs from "layouts/pages/support/contact-us";
// import DesktopApp from "layouts/pages/apps/desktop-app";
// import VirtualRealityPage from "layouts/pages/extra/virtual-reality";

const routes = [
  {
    name: "Nosotros",
    icon: <Icon>dashboard</Icon>,
    columns: 3,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Kinos",
        collapse: [
          {
            name: "acerca de nosotros",
            route: "/company/about-us",
            component: <AboutUs />,
            key: "AboutUs",
          },
          {
            name: "Trabaja con nosotros",
            route: "/company/work-offers",
            component: <WorkOffers />,
            key: "WorkOffers",
          },
          {
            name: "servicios",
            route: "/company/services",
            component: <Services />,
            key: "Services",
          },
        ],
      },
      {
        name: "soporte",
        collapse: [
          {
            name: "centro de ayuda",
            route: "/support/help-center",
            component: <HelpCenter />,
            key: "HelpCenter",
          },
          {
            name: "quiero comprar",
            route: "/company/buy",
            component: <Buy />,
            key: "Buy",
          },
          {
            name: "quiero vender",
            route: "/company/sell",
            component: <Sell />,
            key: "Sell",
          },
          {
            name: "contáctanos",
            route: "/support/contact-us",
            component: <ContactUs />,
            key: "ContactUs",
          },
          {
            name: "faq",
            route: "/faq",
            component: <Faq />,
            key: "Faq",
          },
          {
            name: "privacidad",
            route: "/privacy",
            component: <Privacy />,
            key: "Privacy",
          },
          {
            name: "legal",
            route: "/legal",
            component: <Legal />,
            key: "Legal",
          },
        ],
      },
    ],
  },
  {
    name: "Activos",
    icon: <Icon>dashboard</Icon>,
    columns: 3,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Oferta",
        collapse: [
          {
            name: "Inicio",
            route: "/",
            component: <Home />,
            key: "Home",
          },
          {
            name: "Obra Nueva",
            route: "/pages/landing-pages/new-development",
            component: <NewDevelopment />,
            key: "NewDevelopment",
          },
          {
            name: "Residencial",
            route: "/pages/landing-pages/residential",
            component: <Residential />,
            key: "Residential",
          },
          {
            name: "Alquiler",
            route: "/pages/landing-pages/rental",
            component: <Rental />,
            key: "Rental",
          },
        ],
      },
      {
        name: "Demanda",
        collapse: [
          {
            name: "Demanda de activos",
            route: "/pages/landing-pages/demand",
            component: <Demand />,
            key: "Demand",
          },
        ],
      },
    ],
  },
  {
    name: "docs",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Comprar una casa",
        description: "Todo acerca de comprar una casa en España",
        href: "http://kinos.es/learning/",
        key: "Buy",
      },
      {
        name: "Documentos para Brokers",
        description: "Documentos que debes conocer",
        href: "http://kinos.es/learning/",
        key: "BrokersDocs",
      },
      {
        name: "Impuestos",
        description: "Todo acerca de gravamenes impositivos",
        href: "http://kinos.es/learning/",
        key: "Taxes",
      },
      {
        name: "Relocación",
        description: "Servicios de relocación e información sobre migración",
        href: "http://kinos.es/learning-/",
        key: "Relocation",
      },
    ],
  },
  {
    name: "Noticias",
    icon: <Icon>dashboard</Icon>,
    columns: 3,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "blogs",
        collapse: [
          {
            name: "Residencial",
            route: "/blogs/residential-news",
            component: <ResidentialNews />,
            key: "ResidentialNews",
          },
          {
            name: "Obra nueva",
            route: "/blogs/new-development-news",
            component: <NewDevelopmentNews />,
            key: "NewDevelopmentNews",
          },
          {
            name: "Life Style",
            route: "/blogs/lifestyle-news",
            component: <LifeStyleNews />,
            key: "LifeStyleNews",
          },
        ],
      },
    ],
  },
  {
    name: "ES => EN",
    route: "https://en.kinos.es",
    icon: <Icon>language</Icon>,
    key: "EN",
  },
];

export default routes;
