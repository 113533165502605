/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Fragment } from "react";
import TextTruncate from "react-text-truncate";
// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Stack from "@mui/material/Stack";

function SimpleAssetCard({
  image,
  id,
  title,
  description,
  bedrooms,
  categories,
  rent,
  sale,
  action,
  asset,
}) {
  function renderPrecio() {
    switch (asset.n_viviendas_disponibles) {
      case 0:
        return `Desde: ${asset.precio_min}` || "Preguntar";
      case 1:
        return `Precio: ${asset.viviendas[0].attributes.precio}` || "Preguntar";
      default:
        return `Desde: ${asset.precio_min}` || "Preguntar";
    }
  }

  return (
    <Card key={id}>
      <Link to={action.route}>
        <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
          <MKBox
            component="img"
            src={`https://wsrv.nl/?url=${image}`}
            alt={title}
            borderRadius="lg"
            shadow="md"
            width="100%"
            position="relative"
            zIndex={1}
          />
          <MKBox
            borderRadius="lg"
            shadow="md"
            width="100%"
            height="100%"
            position="absolute"
            left={0}
            top={0}
            sx={{
              backgroundImage: `url(https://wsrv.nl/?url=${image})`,
              transform: "scale(0.94)",
              filter: "blur(12px)",
              backgroundSize: "cover",
            }}
          />
        </MKBox>
      </Link>

      <MKBox p={3} mt={-2}>
        {categories.length > 0 && (
          <MKTypography
            display="block"
            variant="button"
            color="text"
            fontWeight="regular"
            mb={0.75}
          >
            {categories.map((category) => (
              <Fragment key={category}>{category}&nbsp;&bull;&nbsp;</Fragment>
            ))}
          </MKTypography>
        )}
        <MKTypography variant="h6" fontWeight="bold">
          {id}
        </MKTypography>
        <MKTypography display="inline" variant="h5" fontWeight="bold">
          {title}
        </MKTypography>
        <Stack direction="row" spacing={2}>
          {rent && (
            <MKButton
              variant="contained"
              size="small"
              color="info"
              key="alquiler"
              component={Link}
              to={action.route}
            >
              Alquiler&nbsp;
            </MKButton>
          )}
          {sale && (
            <MKButton
              variant="contained"
              size="small"
              color="info"
              key="venta"
              component={Link}
              to={action.route}
            >
              Venta
            </MKButton>
          )}
        </Stack>
        <MKTypography variant="body2" fontWeight="bold" color="info">
          {renderPrecio()}
          Viviendas: {asset.n_viviendas_disponibles} Hab.: {bedrooms}
        </MKTypography>
        <MKBox mt={1} mb={3}>
          <MKTypography variant="body2" component="p" color="text">
            <TextTruncate
              line={3}
              element="span"
              truncateText="…"
              text={description}
              // textTruncateChild={<a href="/">Ver más</a>}
            />
          </MKTypography>
        </MKBox>
        {action.type === "external" ? (
          <MKButton
            component={MuiLink}
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant="outlined"
            size="small"
            color={action.color ? action.color : "dark"}
          >
            {action.label}
          </MKButton>
        ) : (
          <MKButton
            component={Link}
            to={action.route}
            variant="outlined"
            size="small"
            color={action.color ? action.color : "dark"}
          >
            {action.label}
          </MKButton>
        )}
      </MKBox>
    </Card>
  );
}

// Setting default props for the SimpleBookingCard
SimpleAssetCard.defaultProps = {
  categories: [],
  bedrooms: "Preguntar",
  rent: false,
  sale: false,
};

// Typechecking props for the SimpleBookingCard
SimpleAssetCard.propTypes = {
  image: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bedrooms: PropTypes.any,
  description: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  rent: PropTypes.any,
  sale: PropTypes.any,
  asset: PropTypes.any.isRequired,
};

export default SimpleAssetCard;
