/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Kcomponents footer and navbar
import DefaultNavbar from "Kcomponents/Navbars/DefaultNavbar";
import DefaultFooter from "Kcomponents/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function Privacy() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "http://www.torusred.com",
          label: "Torus",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    POLITICA DE PRIVACIDAD
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Última modificación: Sept 10 2022
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    IDENTIDAD Y DETALLES DE CONTACTO
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Kinos & Partners S.L. Accesible{" "}
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="/contact"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                    >
                      aquí
                    </MKTypography>
                    , domiciliada en Avenida Simón Bolívar 17 CP 29011 Málaga España, titular de
                    cédula patronal B 92401777, con número de teléfono +34 95226021 (en adelante
                    &quot;Kinos&quot; o la &quot;Empresa&quot;) es el responsable del tratamiento de
                    sus datos personales.
                    <br />
                    Esta política de privacidad proporciona información sobre el uso que la Compañía
                    debe hacer de sus datos personales como usuario de la web (&quot;el sitio
                    web&quot;). El propósito de esta Política de privacidad es proporcionar
                    información sobre sus derechos en virtud del Reglamento general de protección de
                    datos (&quot;GDPR&quot;).
                    <br />
                    Si tiene alguna duda sobre el tratamiento de sus datos, póngase en contacto con
                    la Compañía en la siguiente dirección: protecciondatos@kinos.es Le informamos
                    que el Grupo Kinos (al que pertenece la Compañía) dispone de un Delegado de
                    Protección de Datos al que podrá contactar en la siguiente dirección de correo
                    electrónico: protecciondatos@kinos.es
                    <br />
                    This privacy policy applies only to our online activities and is valid for
                    visitors to our website with regards to the information that they shared and/or
                    collect in{" "}
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="https://www.kinos.es"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                    >
                      Kinos
                    </MKTypography>
                    . This policy is not applicable to any information collected offline or via
                    channels other than this website.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Managing Your Information
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    You must not:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Republish material from Kinos
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Sell, rent or sub-license material from Kinos
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Reproduce, duplicate or copy material from Kinos
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Redistribute content from Kinos
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="body2" color="text">
                    This Agreement shall begin on the date hereof. Our Terms and Conditions were
                    created with the help of the{" "}
                    <MKTypography
                      variant="body2"
                      color="text"
                      component="a"
                      href="https://www.kinos.es"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms And Conditions Generator
                    </MKTypography>{" "}
                    and the{" "}
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="https://www.creative-tim.com"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                    >
                      Privacy Policy Generator
                    </MKTypography>
                    .
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    INFORMACIÓN NECESARIA Y ACTUALIZADA
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Es obligatorio cumplimentar todos los campos marcados con un asterisco (*) en
                    los formularios del sitio web. El no cumplimentar alguno de estos campos podría
                    impedir la prestación de los servicios o la información solicitada. Debe
                    proporcionar información veraz; Con el fin de garantizar que la información
                    facilitada esté siempre actualizada y no contenga errores, deberá informar a la
                    Compañía lo antes posible de cualquier modificación o cambio en sus datos
                    personales enviando un correo electrónico a: protecciondatos@kinos.es Al hacer
                    clic en el botón &quot;Aceptar&quot; (o similar) en dichos formularios, declara
                    que la información y los datos proporcionados son precisos y verdaderos.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    INFORMACIÓN GENERAL: DESCRIPCIÓN DE LA INFORMACIÓN CONTENIDA EN LA POLÍTICA DE
                    PRIVACIDAD
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    En esta política de privacidad encontrará una tabla que contiene la siguiente
                    información para cada uno de los diferentes tipos de tratamiento de datos que
                    realiza la Compañía: Las finalidades del tratamiento de sus datos, es decir, la
                    razón por la que la Empresa está tratando sus datos personales. b) La base legal
                    que habilita a la Empresa para tratar sus datos para cada una de las finalidades
                    indicadas. c) La posible divulgación de sus datos a terceros, así como el motivo
                    de la divulgación. A estos efectos, le informamos que no cedemos sus datos
                    personales a terceros salvo que exista obligación legal de hacerlo. Los
                    procesadores de datos de la Compañía pueden acceder a sus datos personales, es
                    decir, los proveedores de servicios que necesitan acceder a sus datos personales
                    para realizar sus funciones. Los proveedores de servicios que acceden a sus
                    datos personales generalmente operan en los sectores de sistemas de información
                    y tecnología. La siguiente tabla indicará, en su caso, cualquier otro sector en
                    el que puedan operar otros posibles prestadores de servicios de la Compañía que
                    necesiten acceder a sus datos personales. Puede solicitar información más
                    detallada sobre los destinatarios de sus datos enviando un correo electrónico a
                    protecciondatos@kinos.es, indicando los destinatarios concretos en relación con
                    los que requiere información. d) La existencia de posibles transferencias
                    internacionales de datos. En caso de requerir copia de las cláusulas
                    contractuales a las que se hace referencia en la siguiente tabla, por favor
                    contacte con Kinos en el siguiente correo electrónico: protecciondatos@kinos.es
                    e) El período de almacenamiento de los datos proporcionados por usted. Sus datos
                    permanecerán bloqueados, a efectos de la tramitación de reclamaciones legales,
                    administrativas o fiscales, durante los plazos previstos en la legislación
                    aplicable.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    INFORMACIÓN DETALLADA DEL TRATAMIENTO REALIZADO POR LA EMPRESA
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    <MKTypography variant="h6" mt={6} mb={3}>
                      1. PROPOSITO DEL TRATAMIENTO DE DATOS
                    </MKTypography>
                    Gestión de las consultas planteadas a través del formulario de consultas.
                    Gestión del proceso de selección de personal de las empresas del Grupo y de la
                    relación contigo como candidato a puesto. Gestión de su suscripción al Blog de
                    la empresa del Grupo identificada en el formulario de registro. Para gestionar
                    tu suscripción y los comentarios que proporciones en el blog.
                    <MKTypography variant="h6" mt={6} mb={3}>
                      2. BASES LEGALES
                    </MKTypography>
                    El interés legítimo de la Compañía en atender solicitudes de información a
                    través del sitio web. Consentimiento otorgado por usted para gestionar su
                    inclusión en los candidatos a puestos de la base de datos de empresas del grupo
                    Kinos. Relación contractual para la prestación del servicio solicitado, aunque
                    el servicio sea gratuito.
                    <MKTypography variant="h6" mt={6} mb={3}>
                      3. DESTINATARIOS
                    </MKTypography>
                    Sus datos pueden ser divulgados a empresas del Grupo Kinos (el
                    &quot;Grupo&quot;), en base al interés legítimo de Kinos en atender su solicitud
                    de información. Proveedores de servicios adicionales a los indicados en el
                    apartado 3.c no accederán a sus datos personales. Sus datos pueden ser
                    comunicados a empresas del Grupo, sobre la base legal del interés legítimo de
                    Kinos, ya que la herramienta de CV se utiliza para la contratación de personal
                    en todo el Grupo. Además de los proveedores de servicios indicados en la sección
                    3.c, los proveedores de servicios de contratación de personal también pueden
                    acceder a sus datos personales. Las categorías de destinatarios que pueden tener
                    acceso a sus datos son las que se enumeran en la sección 3.c.
                    <MKTypography variant="h6" mt={6} mb={3}>
                      4. TRANSFERENCIAS DE DATOS INTERNACIONALES
                    </MKTypography>
                    Sus datos podrán ser cedidos a empresas del Grupo, para las finalidades
                    indicadas en la columna “Destinatarios” en relación con empresas del Grupo
                    ubicadas fuera del Espacio Económico Europeo (“EEE”) tal y como se establece en
                    este enlace. Estas transferencias se realizan en base a la adhesión de estas
                    empresas al Privacy Shield, si son empresas estadounidenses, o en base a la
                    firma de estas empresas ubicadas fuera del EEE de cláusulas estándar de
                    protección de datos adoptadas por la Comisión Europea para transferencias
                    internacionales. Sus datos podrán ser cedidos a empresas del Grupo, para las
                    finalidades indicadas en la columna “Destinatarios” en relación con empresas del
                    Grupo ubicadas fuera del Espacio Económico Europeo (“EEE”) tal y como se
                    establece en este enlace. Estas transferencias se realizan en base a la adhesión
                    de estas empresas al Privacy Shield, si son empresas estadounidenses, o en base
                    a la firma de estas empresas ubicadas fuera del EEE de cláusulas estándar de
                    protección de datos adoptadas por la Comisión Europea para transferencias
                    internacionales. No se realizarán transferencias internacionales de sus datos
                    para este tratamiento.
                    <MKTypography variant="h6" mt={6} mb={3}>
                      5. PERIODO DE ALMACENAMIENTO
                    </MKTypography>
                    Hasta que se resuelva su solicitud de información. Sus datos permanecerán
                    activos en la base de datos de candidatos de trabajo de Kinos durante 3 años a
                    partir del momento en que proporcionó a Kinos su CV. Una vez transcurrido este
                    plazo, puede resultar de aplicación la obligación de bloqueo de datos impuesta
                    por la legislación. Por un período de tres años después de su última interacción
                    con la Compañía o hasta que indique que no desea continuar suscribiéndose al
                    blog (lo que ocurra primero).
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    EJERCICIO DE SUS DERECHOS
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    <MKTypography variant="body2" color="text">
                      Puede ejercer los siguientes derechos:
                    </MKTypography>
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Derecho de acceso a sus datos personales para saber qué datos se están
                          procesando y las operaciones de procesamiento que se están realizando con
                          esos datos;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Derecho a la rectificación de cualquier dato inexacto;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Derecho al borrado de sus datos personales, cuando sea posible;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Derecho a solicitar la restricción del tratamiento de sus datos personales
                          cuando la veracidad, licitud o necesidad de tratamiento de los datos sea
                          dudosa, en cuyo caso podremos almacenar sus datos para el ejercicio o
                          defensa de reclamaciones;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Derecho a la portabilidad de los datos, cuando la base legal que nos
                          habilite para tratarlos, de las indicadas en la tabla anterior, sea la
                          relación contractual o su consentimiento;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Derecho a oponerse al tratamiento de sus datos personales, cuando la base
                          legal que nos habilite para tratarlos, de las indicadas en el cuadro
                          anterior, sea el interés legítimo. A tales efectos, dejaremos de tratar
                          sus datos salvo que tengamos un interés legítimo imperioso o sea para el
                          establecimiento, ejercicio o defensa de reclamaciones legales.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Derecho a retirar su consentimiento en cualquier momento.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    <br />
                    Puede ejercitar sus derechos en cualquier momento, sin coste alguno, enviando un
                    correo electrónico a protecciondatos@kinos.es indicando el derecho que desea
                    ejercitar y sus datos personales. Si considera que no hemos tratada
                    adecuadamente sus datos personales, puede contactar con el Delegado de
                    Protección de Datos en protecciondatos@kinos.es También puede presentar una
                    reclamación ante la Agencia Española de Protección de Datos si considera que se
                    ha infringido la legislación de protección de datos en el procesamiento de sus
                    datos personales.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    COOKIES
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Kinos solo utilizará dispositivos de almacenamiento y recuperación de datos
                    (“Cookies”) cuando el usuario haya otorgado su consentimiento previo en dicha
                    conexión en línea con lo que se indica en la ventana emergente del navegador web
                    del usuario cuando el usuario accede al sitio web para el primera vez y en el
                    resto de términos y condiciones indicados en la Política de Cookies de Kinos,
                    que todos los usuarios deben conocer.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    MEDIDAS DE SEGURIDAD
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Kinos adoptará las medidas de seguridad requeridas por el RGPD de acuerdo con la
                    naturaleza de los datos procesados de vez en cuando. No obstante, la seguridad
                    técnica de un medio como Internet no es inexpugnable y puede haber una mala
                    conducta intencionada por parte de terceros, aunque Kinos ha puesto en marcha
                    todas las medidas a su alcance para evitar dicha mala conducta.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    MENORES
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Los menores de edad no podrán utilizar los servicios disponibles a través del
                    sitio web sin la previa autorización de sus padres, tutores o representantes
                    legales. Dichos padres, tutores o representantes legales son los únicos
                    responsables de todos los actos realizados a través del sitio web por los
                    menores a su cargo, incluyendo la cumplimentación de formularios con los datos
                    personales de dichos menores y la marcación de las casillas adjuntas.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
