/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKButton from "components/MKButton";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link } from "react-router-dom";
// Coworking page component
// import AboutUsOption from "pages/LandingPages/Home/components/AboutUsOption";

function News() {
  return (
    <Container>
      <MKBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="xl"
        my={24}
        p={6}
        sx={{
          backgroundImage:
            "url(https://images.unsplash.com/photo-1533563906091-fdfdffc3e3c4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80)",
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={8} lg={5}>
            <MKTypography variant="h5" color="white" fontWeight="bold">
              Permanezca informado y consulte nuestros arículos y guías en nuestro BLOG.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: "auto" }}>
            <MKBox width="12rem" ml="auto">
              <MKButton
                component={Link}
                to="/blogs/new-development-news"
                variant="gradient"
                color="warning"
                fullWidth
                sx={{ boxShadow: "none" }}
              >
                Noticias
              </MKButton>
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>
    </Container>
  );
}

export default News;
