/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import { Fragment } from "react";
import PropTypes from "prop-types";
// @mui material components
// import Container from "@mui/material/Container";
// HLink
import { HashLink } from "react-router-hash-link";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import MKTypography from "components/MKTypography";
// Material Kit 2 PRO React components
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import CEImage from "assets/images/Kinos/CE.jpeg";

function Data({ data, categories }) {
  // console.log("IMAGES ARR:", imgArr); // eslint-disable-line no-console
  return (
    <Grid
      item
      xs={12}
      md={5}
      sx={{ mr: { xs: 0, md: "auto" }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
    >
      <Stack spacing={{ xs: 2, md: 2 }}>
        <MKBox key="Ubicacion" display="flex" alignItems="center" p={1}>
          <MKBox display="flex" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              REF:
            </MKTypography>
          </MKBox>
          <MKTypography variant="body2" fontWeight="regular">
            {data.ref}
          </MKTypography>
        </MKBox>
        <MKBox key="Ubicacion" display="flex" alignItems="center" p={1}>
          <MKBox display="flex" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Ubicación:
            </MKTypography>
          </MKBox>
          <MKTypography variant="body2" fontWeight="regular">
            {data.zona}
          </MKTypography>
        </MKBox>
        <MKBox key="Modalidad" mr={0} display="flex" alignItems="center" p={1}>
          <Stack direction="row" spacing={2}>
            {data.to_sell && (
              <HashLink to="#contact" smooth>
                <MKButton variant="contained" size="small" color="info" key="compra">
                  Comprar
                </MKButton>
              </HashLink>
            )}
            {data.to_rent && (
              <HashLink to="#contact" smooth>
                <MKButton variant="contained" size="small" color="info" key="alquiler">
                  Alquilar
                </MKButton>
              </HashLink>
            )}
            <HashLink to="#contact" smooth>
              <MKButton variant="contained" size="small" color="info" key="info">
                Información
              </MKButton>
            </HashLink>
          </Stack>
        </MKBox>
        <MKBox key="Precio" display="flex" alignItems="center" p={1}>
          {data.to_sell ? (
            <MKBox display="flex" alignItems="center" p={1}>
              <MKBox display="flex" mr={2} ml={0} mt={-0.125}>
                <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
                  Precio venta:
                </MKTypography>
              </MKBox>
              <MKTypography variant="body2" fontWeight="regular">
                {data.precio}
              </MKTypography>
            </MKBox>
          ) : (
            ""
          )}
          {data.to_rent ? (
            <MKBox display="flex" alignItems="center" p={1}>
              <MKBox display="flex" mr={2} mt={-0.125}>
                <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
                  Precio alquiler:
                </MKTypography>
              </MKBox>
              <MKTypography variant="body2" fontWeight="regular">
                {data.alquiler}
              </MKTypography>
            </MKBox>
          ) : (
            ""
          )}
        </MKBox>
        <MKBox key="Superficie" display="flex" alignItems="center" p={1}>
          <MKBox display="flex" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Superficie:
            </MKTypography>
          </MKBox>
          <MKTypography variant="body2" fontWeight="regular">
            {data.superficie_util}
          </MKTypography>
        </MKBox>
        <MKBox key="caracteristicas" display="flex" alignItems="center" p={1}>
          <MKBox display="flex" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Características:
            </MKTypography>
          </MKBox>
          {categories.length > 0 && (
            <MKTypography
              display="block"
              variant="body2"
              color="text"
              fontWeight="regular"
              mb={0.75}
            >
              {categories.map((category) => (
                <Fragment key={category}>{category}&nbsp;&bull;&nbsp;</Fragment>
              ))}
            </MKTypography>
          )}
        </MKBox>
        <MKBox key="Dormitorios" display="flex" alignItems="center" p={1}>
          <MKBox display="flex" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Dormitorios:
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={5} mt={-0.125}>
            <MKTypography variant="body2" fontWeight="regular">
              {data.dormitorios}
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Baños:
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={5} mt={-0.125}>
            <MKTypography variant="body2" fontWeight="regular">
              {data.bannos}
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Garaje:
            </MKTypography>
          </MKBox>
          <MKTypography variant="body2" fontWeight="regular">
            {data.garaje}
          </MKTypography>
        </MKBox>
        <MKBox key="Reformado" display="flex" p={1}>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Reformado:
            </MKTypography>
          </MKBox>
          <MKBox display="flex" mr={5} mt={-0.125}>
            <MKTypography variant="body2" fontWeight="regular">
              {data.reformado}
            </MKTypography>
          </MKBox>
          {data.reformado === "Si" && (
            <>
              <MKBox display="flex" justifyContent="center" alignItems="center" mr={2} mt={-0.125}>
                <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
                  Fecha reforma:
                </MKTypography>
              </MKBox>
              <MKTypography variant="body2" fontWeight="regular">
                {data.fecha_reforma}
              </MKTypography>
            </>
          )}
        </MKBox>
        <MKBox key="Orientacion" display="flex" p={1}>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Orientación:
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={5} mt={-0.125}>
            <MKTypography variant="body2" fontWeight="regular">
              {data.orientacion}
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Terraza:
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={5} mt={-0.125}>
            <MKTypography variant="body2" fontWeight="regular">
              {data.terraza}
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox key="certificacion" display="flex" p={1}>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              CE:
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={5} mt={-0.125}>
            <MKTypography variant="body2" fontWeight="regular">
              {data.cert_energetica}
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Fecha CE:
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={5} mt={-0.125}>
            <MKTypography variant="body2" fontWeight="regular">
              {data.cert_energetica_fecha}
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox
          component="img"
          src={CEImage}
          alt="image"
          borderRadius="md"
          maxWidth="150px"
          width="100%"
          mt={-12}
        />
      </Stack>
    </Grid>
  );
}
//
// localidad: {
// id: 3553,
// name: "Málaga",
// seoname: "malaga"
// },
// exterior: true,
// alquiler: null,
// fecha_construccion: "Ask",
// modalidad: {
// id: 1,
// name: "Venta"
// },
// cert_energetica: "Ask",
// metros_playa: "Ask",

// Setting default props for the Data
Data.defaultProps = {
  categories: [],
  data: null,
};

// Typechecking props for the Data
Data.propTypes = {
  categories: PropTypes.any,
  data: PropTypes.any,
};

export default Data;
