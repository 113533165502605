/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import PropTypes from "prop-types";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Stack from "@mui/material/Stack";
// import MKTypography from "components/MKTypography";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import Image from "./Image";
import Data from "./Data";
// import "./gallery.css";
import Tags from "./Tags";

function Info({ data, id }) {
  // console.log("IMAGES ARR:", imgArr); // eslint-disable-line no-console
  return (
    <MKBox component="section" pb={{ xs: 0, md: 0 }} pt={{ xs: 10, md: 10 }} mb={0}>
      <Container>
        <Grid container item xs={12} justifyContent="center">
          <Image img={data.adjunto} />
          <Data data={data} id={id} />
        </Grid>
        <Tags tags={data.tags} />
      </Container>
    </MKBox>
  );
}
//
// localidad: {
// id: 3553,
// name: "Málaga",
// seoname: "malaga"
// },
// exterior: true,
// alquiler: null,
// fecha_construccion: "Ask",
// modalidad: {
// id: 1,
// name: "Venta"
// },
// cert_energetica: "Ask",
// metros_playa: "Ask",

// Setting default props for the Info
Info.defaultProps = {
  data: null,
  id: null,
};

// Typechecking props for the Info
Info.propTypes = {
  data: PropTypes.any,
  id: PropTypes.number,
};

export default Info;
