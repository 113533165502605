/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
// Material Kit 2 PRO React examples
import DefaultBlogCard from "examples/Cards/BlogCards/DefaultBlogCard";

// Images
import author1 from "assets/images/team-2.jpg";

function Posts({ tag }) {
  // const actionProps = {
  //   type: "internal",
  //   route: "/residential/asset",
  //   color: "dark",
  //   label: "find more",
  // };
  const [listData, setListData] = useState([]);
  const [perPage, setPerPage] = useState(12);
  const [total, setTotal] = useState([]);

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/news?per_page=${perPage}`;

    if (tag !== null) {
      url = `${url}&tag=${tag}`;
    }

    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        const myData = data.data; // .map((el) => el.attributes);
        setListData(myData);
        setTotal(data.meta.total);
      });
  }, [perPage]);

  function onMore() {
    setPerPage(perPage + 6);
  }

  function renderAssets(assets) {
    if (typeof assets !== "undefined" && assets.length > 0) {
      return assets.map((asset) => (
        <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
          <MKBox mt={3}>
            <DefaultBlogCard
              image={asset.attributes.adjunto_medium}
              category={{ color: "primary", label: "Residencial" }}
              title={asset.attributes.title}
              description={asset.attributes.leadin}
              // action={actionProps}
              author={{
                image: author1,
                name: "Redacción Kinos",
                date: asset.attributes.fecha_publicacion,
              }}
              action={{ type: "internal", route: `/news/post/${asset.id}` }}
            />
          </MKBox>
        </Grid>
      ));
    }
    return (
      <Grid container spacing={6} alignItems="center" justifyContent="center" direction="column">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h5>Ningún artículo</h5>
        </Grid>
      </Grid>
    );
  }

  return (
    <MKBox component="section" py={7}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={9} textAlign="center" mx="auto">
            {perPage < total ? (
              <MKTypography variant="h3" mb={0.5}>
                Mostrando {perPage} de un total de {total} artículos
              </MKTypography>
            ) : (
              <MKTypography variant="h3" mb={0.5}>
                Mostrando un total de {total} resultados
              </MKTypography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {renderAssets(listData)}
        </Grid>
        <Grid container>
          <Grid item xs={12} spacing={10} textAlign="center" mx="auto">
            {perPage < total ? (
              <>
                <MKTypography variant="h3" mb={0.5}>
                  Mostrando {perPage} de un total de {total} artículos
                </MKTypography>
                <MKButton variant="gradient" color="info" onClick={() => onMore()}>
                  Ver más...
                </MKButton>
              </>
            ) : (
              <MKTypography variant="h3" mb={0.5}>
                Mostrando un total de {total} resultados
              </MKTypography>
            )}
          </Grid>
          <Grid item xs={12} spacing={10} textAlign="center" mx="auto">
            <MKTypography variant="h3" mb={0.5}>
              Ir a otras secciones:
            </MKTypography>
            <MKTypography variant="body2" color="text" px={{ xs: 0, md: 6 }} mb={4}>
              Create a unique and beautiful blog posts. You can also connect your blog directly to
              Google Analytics to have a more detailed look.
            </MKTypography>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={1.5}
              flexWrap="wrap"
              justifyContent={{ xs: "center", lg: "center" }}
            >
              <MKButton
                component={Link}
                to="/blogs/residential-news"
                variant="gradient"
                color="info"
              >
                Noticias Residencial
              </MKButton>
              <MKButton
                component={Link}
                to="/blogs/new-development-news"
                variant="gradient"
                color="info"
              >
                Noticias Obranueva
              </MKButton>
              <MKButton component={Link} to="/blogs/lifestyle-news" variant="gradient" color="info">
                Artículos LifeStyle
              </MKButton>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

Posts.propTypes = {
  tag: PropTypes.string,
};

Posts.defaultProps = {
  tag: null,
};

export default Posts;
