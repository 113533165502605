/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import DemandAsset from "pages/Assets/Demand";

export default function DemandAssetPage() {
  return <DemandAsset />;
}
