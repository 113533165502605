/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedinIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-black.png";
import logoTorusCT from "assets/images/logo-torus.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Kinos & Partners",
    image: logoCT,
    route: "/",
  },
  torus: {
    name: "Torus Red",
    image: logoTorusCT,
    route: "/",
  },

  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/Kinosgroup",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/",
    },
    {
      icon: <LinkedinIcon />,
      link: "https://linkedin.com/",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "Acerca de nosotros", route: "/company/about-us" },
        { name: "Noticias obra nueva", route: "/blogs/new-development-news" },
        { name: "Noticias residencial", route: "/blogs/residential-news" },
        { name: "Noticias life style", route: "/blogs/lifestyle-news" },
        { name: "Programa de afiliados", route: "/company/work-offers" },
      ],
    },
    {
      name: "Sitios web",
      items: [
        { name: "Torus", href: "http://torusred.com" },
        { name: "Kinos Group", href: "http://kinosgroup.com" },
      ],
    },
    {
      name: "Ayuda y soporte",
      items: [
        { name: "Contáctanos", route: "/support/contact-us" },
        { name: "Centro de ayuda", route: "/support/help-center" },
        { name: "FAQ", route: "/faq" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "Términos y condiciones", route: "/legal" },
        { name: "política de privacidad", route: "/privacy" },
        { name: "licencia (EULA)", route: "/privacy" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Kinos.es by{" "}
      <MKTypography
        component="a"
        href="/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Kinos & Partners
      </MKTypography>
      .
    </MKTypography>
  ),
};
