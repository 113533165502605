/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import { useState, useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
import MKTypography from "components/MKTypography";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
// Material Kit 2 PRO React components
import DemandAssetCard from "Kcomponents/Cards/AssetCards/DemandAssetCard";
import Search from "pages/LandingPages/Residential/sections/Search";

function Assets() {
  const [listData, setListData] = useState([]);
  const [perPage, setPerPage] = useState(6);
  const [total, setTotal] = useState([]);
  const [reload, setReload] = useState(false);
  const [formControls, setFormControls] = useState({
    search: "",
    min_price: null,
    max_price: null,
    sup_min: null,
    sup_max: null,
    zone: "",
  });

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/operaciones?per_page=${perPage}&modalidad=demanda`;

    if (formControls.search !== "") {
      url = `${url}&query=${formControls.search}`;
    }

    if (formControls.min_price !== null) {
      url = `${url}&precio_min=${formControls.min_price}`;
    }

    if (formControls.max_price !== null) {
      url = `${url}&precio_max=${formControls.max_price}`;
    }

    if (formControls.sup_min !== null) {
      url = `${url}&superficie_min=${formControls.sup_min}`;
    }

    if (formControls.sup_max !== null) {
      url = `${url}&superficie_max=${formControls.sup_max}`;
    }

    if (formControls.zone !== "") {
      url = `${url}&zona=${formControls.zone}`;
    }

    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        const myData = data.data; // .map((el) => el.attributes);
        setListData(myData);
        setTotal(data.meta.total);
        setReload(false);
      });
  }, [reload]);

  function onMore() {
    setPerPage(perPage + 6);
    setReload(true);
  }

  function renderAssets(assets) {
    if (typeof assets !== "undefined" && assets.length > 0) {
      return assets.map((asset) => (
        <Grid item xs={12} md={6} lg={4} key={asset.attributes.ref}>
          <MKBox mt={3}>
            <DemandAssetCard
              image={asset.attributes.adjunto_medium}
              title={asset.attributes.name}
              description={asset.attributes.short_description}
              volumen={asset.attributes.precio_publico_texto}
              id={asset.attributes.ref}
              action={{
                type: "internal",
                route: `/demand/asset/${asset.id}`,
                color: "info",
                label: "Ir",
              }}
            />
          </MKBox>
        </Grid>
      ));
    }
    return (
      <Grid container spacing={6} alignItems="center" justifyContent="center" direction="column">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h5>No demands yet</h5>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Search
        total={total}
        formControls={formControls}
        setFormControls={setFormControls}
        setReload={setReload}
      />
      <MKBox component="section" py={3}>
        <Grid container textAlign="center" mx="auto" spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={12} lg={12}>
            {perPage < total ? (
              <MKTypography variant="h3" mb={0.5}>
                Mostrando {perPage} de un total de {total} resultados
              </MKTypography>
            ) : (
              <MKTypography variant="h3" mb={0.5}>
                Mostrando un total de {total} resultados
              </MKTypography>
            )}
          </Grid>
        </Grid>
        <Container>
          <Grid container textAlign="center" mx="auto" spacing={3} sx={{ mt: 3 }}>
            {renderAssets(listData)}
          </Grid>
          <Grid container textAlign="center">
            <Grid item xs={12} md={12} lg={12}>
              {perPage < total ? (
                <MKTypography variant="h3" mb={0.5}>
                  Mostrando {perPage} de un total de {total} resultados
                </MKTypography>
              ) : (
                <MKTypography variant="h3" mb={0.5}>
                  Mostrando un total de {total} resultados
                </MKTypography>
              )}
            </Grid>
            <Grid item xs={12} spacing={10} textAlign="center" mx="auto">
              {perPage < total ? (
                <MKButton variant="gradient" color="info" onClick={() => onMore()}>
                  Ver más...
                </MKButton>
              ) : (
                "Fin"
              )}
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Assets;
