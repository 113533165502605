/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import PropTypes from "prop-types";
// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import MKTypography from "components/MKTypography";
// HLink
import { HashLink } from "react-router-hash-link";
// Material Kit 2 PRO React components
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
// Components
function Data({ data }) {
  // console.log("IMAGES ARR:", imgArr); // eslint-disable-line no-console
  return (
    <Grid
      item
      xs={12}
      md={5}
      sx={{ mr: { xs: 0, md: "auto" }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
    >
      <Stack spacing={{ xs: 2, md: 2 }}>
        <MKBox key="REF" display="flex" alignItems="center" p={1}>
          <MKBox display="flex" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              REF:
            </MKTypography>
          </MKBox>
          <MKTypography variant="body2" fontWeight="regular">
            {data.ref}
          </MKTypography>
        </MKBox>
        <MKBox key="Ubicacion" display="flex" alignItems="center" p={1}>
          <MKBox display="flex" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Ubicación:
            </MKTypography>
          </MKBox>
          <MKTypography variant="body2" fontWeight="regular">
            {data.zona}
          </MKTypography>
        </MKBox>
        <MKBox key="Precio" display="flex" alignItems="center" p={1}>
          <MKBox display="flex" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Precio:
            </MKTypography>
          </MKBox>
          <MKTypography variant="body2" fontWeight="regular">
            {data.precio_min} - {data.precio_max}
          </MKTypography>
        </MKBox>
        <MKBox key="NViviendas" display="flex" alignItems="center" p={1}>
          <MKBox display="flex" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Nº Vivivendas disponibles:
            </MKTypography>
          </MKBox>
          <MKTypography variant="body2" fontWeight="regular">
            {data.n_viviendas}
          </MKTypography>
        </MKBox>
        <MKBox key="Superficie" display="flex" alignItems="center" p={1}>
          <MKBox display="flex" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Superficie:
            </MKTypography>
          </MKBox>
          <MKTypography variant="body2" fontWeight="regular">
            {data.superficie_min} - {data.superficie_max}
          </MKTypography>
        </MKBox>
        <MKBox key="Dormitorios" display="flex" alignItems="center" p={1}>
          <MKBox display="flex" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Dormitorios:
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={5} mt={-0.125}>
            <MKTypography variant="body2" fontWeight="regular">
              {data.dormitorios_min} - {data.dormitorios_max}
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Baños:
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={5} mt={-0.125}>
            <MKTypography variant="body2" fontWeight="regular">
              {data.bannos_min} - {data.bannos_max}
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Garaje:
            </MKTypography>
          </MKBox>
          <MKTypography variant="body2" fontWeight="regular">
            {data.garaje}
          </MKTypography>
        </MKBox>
        <MKBox key="Orientacion" display="flex" p={1}>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={2} mt={-0.125}>
            <MKTypography variant="h5" sx={{ lineHeight: 0 }}>
              Terraza:
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="center" alignItems="center" mr={5} mt={-0.125}>
            <MKTypography variant="body2" fontWeight="regular">
              {data.terraza_min} - {data.terraza_max}
            </MKTypography>
          </MKBox>
        </MKBox>
        <HashLink to="#contact" smooth>
          <MKButton variant="contained" size="small" color="info" key="info">
            Información
          </MKButton>
        </HashLink>
      </Stack>
    </Grid>
  );
}
//
// localidad: {
// id: 3553,
// name: "Málaga",
// seoname: "malaga"
// },
// exterior: true,
// alquiler: null,
// fecha_construccion: "Ask",
// modalidad: {
// id: 1,
// name: "Venta"
// },
// cert_energetica: "Ask",
// metros_playa: "Ask",

// Setting default props for the Data
Data.defaultProps = {
  data: null,
};

// Typechecking props for the Data
Data.propTypes = {
  data: PropTypes.object,
};

export default Data;
