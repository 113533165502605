/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import React from "react";
// import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import GoogleMaps from "simple-react-google-maps";
// Material Kit 2 PRO React components
// import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Map({ description, latitude, longitude }) {
  return (
    <Container>
      <Grid container>
        <Grid item xs={12} lg={12} mb={10}>
          <MKTypography variant="h5" my={1} mb={10}>
            Mapa: {description}
          </MKTypography>
          <GoogleMaps
            apiKey="AIzaSyA42726T2lipqBYT7F5P98GFSH5rQ9MiSo"
            style={{ height: "400px", width: "100%" }}
            zoom={11}
            center={{ lat: latitude, lng: longitude }}
            markers={{ lat: latitude, lng: longitude }} // optional
          />
        </Grid>
      </Grid>
    </Container>
  );
}

// Setting default props for the Description
Map.defaultProps = {
  description: "",
  latitude: 36.7351762,
  longitude: -4.43397,
};

// Typechecking props for the Description
Map.propTypes = {
  description: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
};

export default Map;
