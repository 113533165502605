/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import { Link } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Stack from "@mui/material/Stack";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "Kcomponents/Navbars/DefaultNavbar";
import DefaultFooter from "Kcomponents/Footers/DefaultFooter";

// Rental page sections
import Assets from "pages/LandingPages/NewDevelopment/sections/Assets";
// import Testimonials from "pages/LandingPages/NewDevelopment/sections/Testimonials";
// import Faq from "pages/LandingPages/NewDevelopment/sections/Faq";
import Contact from "pages/LandingPages/NewDevelopment/sections/Contact";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/Kinos/bg-newdevelopment.jpg";

function NewDevelopment() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "http://torusred.com",
          label: "Torus",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Encuentra tu nueva casa
            </MKTypography>
            <MKTypography variant="body1" color="white" mt={1}>
              Nuevas promociones, complejos, para primera y segunda vivienda vacacional.
            </MKTypography>
            <Stack direction="row" spacing={1} mt={6} mb={3}>
              <MKButton
                variant="gradient"
                color="info"
                component={Link}
                to="/pages/landing-pages/residential"
              >
                Residencial
              </MKButton>
              <MKButton
                variant="gradient"
                color="info"
                component={Link}
                to="/pages/landing-pages/rental"
              >
                Alquileres
              </MKButton>
              <MKButton
                variant="gradient"
                color="info"
                component={Link}
                to="/pages/landing-pages/demand"
              >
                Demanda de activos
              </MKButton>
            </Stack>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        <Assets />
        <Contact />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default NewDevelopment;
