/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import PropTypes from "prop-types";
// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
// import MKTypography from "components/MKTypography";
// Material Kit 2 PRO React components
// import MKBox from "components/MKBox";

function Image({ img }) {
  // const imgArr = images.map((img) => ({
  //   original: `https://wsrv.nl/?url=${img.attributes.adjunto}`,
  //   thumbnail: `https://wsrv.nl/?url=${img.attributes.adjunto_thumb}`,
  // }));
  // console.log("IMAGES ARR:", imgArr); // eslint-disable-line no-console
  return (
    <Grid
      item
      xs={12}
      md={5}
      sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
    >
      <Stack
        component="img"
        spacing={{ xs: 4, md: 8 }}
        sx={{
          height: 333,
          width: 450,
          maxHeight: { xs: 333, md: 467 },
          maxWidth: { xs: 450, md: 550 },
        }}
        alt="Image"
        src={`https://wsrv.nl/?url=${img}`}
      />
    </Grid>
  );
}

// Setting default props for the Gallery
Image.defaultProps = {
  img: "",
};

// Typechecking props for the Image
Image.propTypes = {
  img: PropTypes.any,
};

export default Image;
