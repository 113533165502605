/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import PropTypes from "prop-types";
// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
// import MKTypography from "components/MKTypography";
// Material Kit 2 PRO React components
// import MKBox from "components/MKBox";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function Gallery({ images }) {
  const imgArr = images.map((img) => ({
    original: `https://wsrv.nl/?url=${img.attributes.adjunto_large}`,
    thumbnail: `https://wsrv.nl/?url=${img.attributes.adjunto_thumb}`,
  }));
  // console.log("IMAGES ARR:", imgArr); // eslint-disable-line no-console
  return (
    <Grid
      item
      xs={12}
      md={5}
      sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
    >
      <Stack spacing={{ xs: 4, md: 8 }}>
        <ImageGallery items={imgArr} showIndex="true" />
      </Stack>
    </Grid>
  );
}
//
// localidad: {
// id: 3553,
// name: "Málaga",
// seoname: "malaga"
// },
// exterior: true,
// alquiler: null,
// fecha_construccion: "Ask",
// modalidad: {
// id: 1,
// name: "Venta"
// },
// cert_energetica: "Ask",
// metros_playa: "Ask",

// Setting default props for the Gallery
Gallery.defaultProps = {
  images: [],
};

// Typechecking props for the Gallery
Gallery.propTypes = {
  images: PropTypes.any,
};

export default Gallery;
