/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
// import TextTruncate from "react-text-truncate";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Kcomponents footer and navbar
import DefaultNavbar from "Kcomponents/Navbars/DefaultNavbar";
import DefaultFooter from "Kcomponents/Footers/DefaultFooter";

// Home page sections
import Information from "pages/LandingPages/Home/sections/Information";
import Testimonials from "pages/LandingPages/Home/sections/Testimonials";
import AboutUs from "pages/LandingPages/Home/sections/AboutUs";
import Info from "pages/Assets/Residential/sections/Info";
import Description from "pages/Assets/Residential/sections/Description";
import Contact from "pages/Assets/Residential/sections/Contact";
import News from "pages/Assets/Residential/sections/News";
import Map from "Kcomponents/Maps/Simple";
// import CE from "Kcomponents/Tables/CE";
import Legal1 from "Kcomponents/LegalDisclaimers/AssetDisclaimerCard";
import Legal2 from "Kcomponents/LegalDisclaimers/AssetLegalCard";
// import Places from "pages/LandingPages/Home/sections/Places";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Social from "./sections/Social";
// Images
// import bgImage from "assets/images/Kinos/bg-home.jpg";

function Residential() {
  const [assetData, setAssetData] = useState({ id: null, images: [{ attributes: {} }] });
  const { id } = useParams();
  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/viviendas/${id}`;
    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        const myData = data.data.attributes; // .map((el) => el.attributes);
        setAssetData(myData);
      });
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://torusred.com",
          label: "Torus",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(https://wsrv.nl/?url=${assetData.images[0].attributes.adjunto})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "start" }}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {assetData.name}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              mt={1}
              pr={{ md: 12, lg: 24, xl: 32 }}
              opacity={0.8}
            >
              {assetData.short_description}
            </MKTypography>
            <Stack direction="row" spacing={1} mt={6} mb={3}>
              <MKButton
                variant="gradient"
                color="info"
                component={Link}
                to="/pages/landing-pages/new-development"
              >
                Obra Nueva
              </MKButton>
              <MKButton
                variant="gradient"
                color="info"
                component={Link}
                to="/pages/landing-pages/residential"
              >
                Residencial
              </MKButton>
              <MKButton
                variant="gradient"
                color="info"
                component={Link}
                to="/pages/landing-pages/rental"
              >
                Alquileres
              </MKButton>
              <MKButton
                variant="gradient"
                color="info"
                component={Link}
                to="/pages/landing-pages/demand"
              >
                Demanda de activos
              </MKButton>
            </Stack>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Info
          images={assetData.images}
          data={assetData}
          categories={assetData.caracteristicas}
          id={id}
        />
        <Description description={assetData.description} />
        <Social shortDescription={assetData.short_description} name={assetData.name} id={id} />
        <Map
          description={assetData.lat}
          latitude={assetData.latitud}
          longitude={assetData.longitud}
        />
        <Contact image={assetData.images[0].attributes.adjunto} id={id} />
        <Information />
        <Testimonials />
        <AboutUs />
        <News />
        <Legal1
          icon="gavel"
          title="Exención de responsabilidad"
          action={{
            type: "internal",
            route: "/privacy",
            label: "Ver más",
          }}
        />
        <Legal2
          icon="gavel"
          title="Información legal"
          action={{
            type: "internal",
            route: "/privacy",
            label: "Ver más",
          }}
        />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Residential;
