/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import MKSnackbar from "components/MKSnackbar";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

function Contact({ image, id }) {
  const [isSent, setIsSent] = useState(false);
  const [formControls, setFormControls] = useState({
    sender_name: "",
    sender_phone: "",
    sender_mail: "",
    message: "",
    subject: `I want information about ref: ${id}`,
    website: `Kinos.es ES - RESIDENCIAL - REF: ${id}`,
    zone: "",
  });

  const [show, setShow] = useState(false);
  const [accept, setAccept] = useState(true);

  function toggleSnackbar(e) {
    e.preventDefault();
    setShow(!show);
  }

  function toggleAccept() {
    // e.preventDefault();
    setAccept(!accept);
  }

  function onChange(e) {
    const { name } = e.target;
    const { value } = e.target;
    setFormControls({
      ...formControls,
      [name]: value,
    });
  }

  function onSubmit(e) {
    e.preventDefault();
    // get our form data out of state
    // const { formControls } = this.state;
    const url = `${process.env.REACT_APP_API_URL}/messages/contact`;
    axios.post(url, { formControls }).then((result) => {
      setIsSent(true);
      setShow(true);
      console.log(result); // eslint-disable-line no-console
    });
  }

  return (
    <MKBox
      component="section"
      display="grid"
      position="relative"
      minHeight="90vh"
      borderRadius="xl"
      mr={{ xs: 0, lg: -2 }}
      mb={{ xs: 0, lg: -2 }}
      sx={{ overflow: { xs: "hidden", lg: "visible" }, placeItems: "center" }}
      id="contact"
    >
      <MKBox
        component="img"
        alt="lake house"
        src={`https://wsrv.nl/?url=${image}`}
        width={{ xs: "100%", lg: "50%" }}
        height={{ xs: "100%", lg: "100%" }}
        position="absolute"
        right={0}
        bottom={{ xs: "-25%", lg: "unset" }}
        top={{ xs: 0, lg: "unset" }}
        sx={{
          objectFit: "cover",
          borderTopLeftRadius: ({ borders: { borderRadius } }) => ({ xs: 0, lg: borderRadius.lg }),
        }}
      />
      <Container>
        <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: 0, lg: 12 } }}>
          <Grid item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              shadow="lg"
              borderRadius="xl"
              sx={{
                backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                  rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                px: { xs: 3, sm: 6 },
                py: { xs: 3, sm: 8 },
                mb: { xs: 0, lg: 8 },
                mt: { xs: 0, lg: -6 },
              }}
            >
              <MKTypography variant="h3" textAlign="center" mb={2}>
                Contáctenos si desea más información
              </MKTypography>
              <MKBox component="form" method="post" autoComplete="off">
                <MKBox py={3} px={{ xs: 3, md: 12, lg: 6, xl: 12 }}>
                  <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12} md={6} lg={12} xl={6}>
                      <MKInput
                        variant="standard"
                        placeholder="Nombre"
                        label="Nombre completo"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        name="sender_name"
                        value={formControls.sender_name}
                        onChange={(e) => onChange(e)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} xl={6}>
                      <MKInput
                        variant="standard"
                        placeholder="eg.+34 000 000"
                        label="Teléfono"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        name="sender_phone"
                        value={formControls.sender_phone}
                        onChange={(e) => onChange(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ my: 1 }}>
                      <MKInput
                        variant="standard"
                        type="email"
                        placeholder="correo@dominio.tld"
                        label="Correo electrónico"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        name="sender_mail"
                        value={formControls.sender_mail}
                        onChange={(e) => onChange(e)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="Mensaje"
                        placeholder="Su mensaje"
                        rows={4}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        name="message"
                        value={formControls.message}
                        onChange={(e) => onChange(e)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sx={{ mb: 3, ml: -1 }}>
                      <Switch
                        id="flexSwitchCheckDefault"
                        defaultChecked
                        onChange={() => toggleAccept()}
                      />
                      <MKTypography
                        component="label"
                        variant="button"
                        color="text"
                        fontWeight="regular"
                        htmlFor="flexSwitchCheckDefault"
                        sx={{ userSelect: "none", cursor: "pointer" }}
                      >
                        Acepto los{" "}
                        <MKTypography component="a" href="#" variant="button" fontWeight="regular">
                          <u>Términos y condiciones</u>
                        </MKTypography>
                        .
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12}>
                      {isSent ? (
                        <MKButton type="submit" variant="gradient" color="dark" fullWidth disabled>
                          Mensaje enviado
                        </MKButton>
                      ) : (
                        <MKButton
                          type="submit"
                          variant="gradient"
                          color="dark"
                          onClick={(e) => onSubmit(e)}
                          fullWidth
                          disabled={!accept}
                        >
                          Enviar mensaje
                        </MKButton>
                      )}
                    </Grid>
                  </Grid>
                </MKBox>
              </MKBox>
              <MKSnackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                color="dark"
                icon="notifications"
                title="Kinos & Partners"
                content="El mensaje se ha enviado correctamente"
                dateTime="1 mins ago"
                open={show}
                close={(e) => toggleSnackbar(e)}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Setting default props for the Contact
Contact.defaultProps = {
  image: "",
  id: null,
};

// Typechecking props for the Contact
Contact.propTypes = {
  image: PropTypes.any,
  id: PropTypes.string,
};

export default Contact;
