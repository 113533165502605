/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import AboutUsOption from "pages/LandingPages/Home/components/AboutUsOption";

function AboutUs() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container>
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}>
              Acerca de nosotros
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              Kinos asesor de inversiones, somos asesores en la busqueda de oportunidades de
              inversión, trabajamos con amplia cartera de clientes de acuerdo y centrado en las
              necesidades e intereses de los inversores a nivel mundial.
            </MKTypography>
            <MKTypography
              component="a"
              href="/company/about-us"
              variant="button"
              color="info"
              fontWeight="regular"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            >
              Más acerca de nosotros
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <AboutUsOption
                icon="mediation"
                content={
                  <>
                    Encuentra tu residencia exclusiva, villas, casas, apartamentos y áticos
                    <br />
                    en las mejores ubicaciones.
                  </>
                }
              />
              <AboutUsOption
                icon="settings_overscan"
                content={
                  <>
                    Nuevas promociones, complejos primera y segunda vivienda vacacional.
                    <br />
                    Amplio espectro de asesoría técnica sobre oportunidades en obra nueva.
                  </>
                }
              />
              <AboutUsOption
                icon="token"
                content={
                  <>
                    Cubrimos todos los aspectos de la evaluación, análisis, gestión de
                    <br />
                    documentación y validación de oportunidades de inversión.
                  </>
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AboutUs;
