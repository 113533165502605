/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/
/* eslint-disable import/no-unresolved */
// SwiperJS
import { Autoplay, Navigation } from "swiper/modules";

// SwiperJS react components
import { Swiper, SwiperSlide } from "swiper/react";

// SwiperJS styles
import "swiper/css";
import "swiper/css/navigation";

// Pricing page components
import SliderHeader from "pages/Company/Services/components/Header";

// Images
import bg1 from "assets/images/bg2.jpg";
import bg2 from "assets/images/bg.jpg";
import bg3 from "assets/images/dg1.jpg";

function Header() {
  // install SwiperJS modules
  // SwiperCore.use([Autoplay, Navigation]);

  return (
    <Swiper
      modules={[Navigation, Autoplay]}
      autoplay={{ delay: 5000 }}
      speed={800}
      spaceBetween={0}
      slidesPerView={1}
      navigation
      loop
    >
      <SwiperSlide>
        <SliderHeader
          image={bg1}
          label="Intermediación compra, venta y alquiler"
          title="Residencial y Obra Nueva"
          description="Cuando se trata de comprar y vender propiedades, asesoramos en todos los mercados residenciales y comerciales."
          cards={[
            {
              variant: "gradient",
              color: "info",
              icon: "shuffle",
              title: "Servicios de asesoría y transacción",
              description:
                "Con nuestros especialistas dedicados en nuestro negocio de servicios de transacciones, podemos brindarle a usted, nuestro cliente, una combinación de conocimientos inmobiliarios, financieros, comerciales y operativos para cada caso.",
            },
            {
              variant: "contained",
              color: "info",
              icon: "redeem",
              title: "Gestión y administración de propiedades",
              description:
                "Gestionamos estratégicamente sus activos inmobiliarios para incrementar su valor En Kinos entendemos que tenemos tres clientes en la gestión de activos: el propietario, los inquilinos y los clientes relacionados y proveedores con ambos.",
            },
            {
              variant: "contained",
              color: "info",
              icon: "bookmarks",
              title: "Venta de propiedades",
              description:
                "Property Sales ofrece disposiciones de inversión personalizadas, servicios de adquisición y recapitalización, junto con inteligencia de mercado líder en la industria, para brindar a nuestros clientes una exposición inigualable a los compradores validando cada perfil. ",
            },
          ]}
        />
      </SwiperSlide>
      <SwiperSlide>
        <SliderHeader
          image={bg2}
          label="Marketing y ventas de nuevos desarrollos inmobiliarios"
          title="Servicios para Promotores e Inversores"
          description="Conexión entre altos inversores inmobiliarios, promotores y entidades especializadas en financiación de proyectos inmobiliarios en España, Portugal y a nivel internacional. Ventas y comercialización de proyectos en modelo de contrato exclusividad. Innovación en modelo Comercialización exclusiva más gestión colaborativa de proyectos inmobiliarios."
          cards={[
            {
              variant: "contained",
              color: "primary",
              icon: "shuffle_on",
              title: "Consultoría",
              description: "Inteligencia de mercado, diseño de producto, análisis de precios.",
            },
            {
              variant: "gradient",
              color: "primary",
              icon: "beenhere",
              title: "Marketing de proyectos",
              description:
                "Marca y posicionamiento, estrategia de lanzamiento, ejecución de marketing",
            },
            {
              variant: "contained",
              color: "primary",
              icon: "ballot",
              title: "Comunicaciones",
              description: "Planificación y compra de medios, planing de eventos",
            },
          ]}
        />
      </SwiperSlide>
      <SwiperSlide>
        <SliderHeader
          image={bg3}
          label="Gestión de activos"
          title="Gestionamos su cartera de activos"
          description="Gestión estratégica de una cartera de activos o inmuebles además de la estructura societaria en la que se basa la inversión."
          cards={[
            {
              variant: "contained",
              color: "error",
              icon: "tune",
              title: "Planeamiento",
              description:
                "Apoyo en el diseño y materialización de estructuras de inversión y el diseño de planes de negocio.",
            },
            {
              variant: "contained",
              color: "error",
              icon: "settings_suggest",
              title: "Gestión",
              description:
                "Ejecución de planes de negocio, explotación comercial y gestión societaria (contable y fiscal).",
            },
            {
              variant: "gradient",
              color: "error",
              icon: "compare",
              title: "Estrategia de salida",
              description:
                "Preparación de data room, soporte en la negociación de venta y ejecución de condición post transacción. Debida diligencia vendedor / comprador para identificar riesgos, con la participación de todas las áreas especializadas.",
            },
          ]}
        />
      </SwiperSlide>
    </Swiper>
  );
}

export default Header;
