/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";

import office1 from "assets/images/Kinos/AboutUs/office1.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid
            container
            item
            xs={12}
            md={9}
            justifyContent="center"
            sx={{ mx: "auto", mb: 8, textAlign: "center" }}
          >
            <MKTypography variant="h2" mb={1}>
              Díganos los que busca, gestionamos de forma personalizada su petición para ofertarle
              inmuebles según sus criterios de búsqueda
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Acceda a inmuebles fuera de mercado no publicados.
              <br />
              Cada vez más muchos propietarios, quieren mantener la privacidad de sus inmuebles y no
              quieren que aparezcan en listados públicos, de forma que la información e imágenes de
              sus casas sólo sean facilitadas y ofertadas a cliente previamente registrados y
              validados por nuestra empresa.
              <br />
              Publicamos un perfil con su criterio de búsqueda ( zona, presupuesto ) para que otros
              agentes, propietarios o profesionales asociados puedan inscribir su oferta de inmueble
              y llevamos a cabo un procesos más privado y discrecional de búsqueda de cruzar oferta
              y demanda.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start" spacing={5}>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    title="¿Qiénes somos?"
                    description="Kinos fue establecida en 2003 por sus socios fundadores Joaquín Morales y Margarita Morales en las últimas décadas, han acumulado un currículum completo de educación y experiencia en administración de empresas y bienes raíces. También ha establecido una sólida red de contactos en la industria y lazos con España, Europa, Estados Unidos y la comunidad global. Gestiona las operaciones diarias de la empresa y supervisa todas las áreas de operaciones."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <RotatingCard>
                  <RotatingCardFront
                    image={office1}
                    icon="touch_app"
                    title={
                      <>
                        ¿Quienes
                        <br />
                        Somos?
                      </>
                    }
                    description="Conoce nuestra empresa, historia y motivaciones."
                  />
                  <RotatingCardBack
                    image={office1}
                    title="Conócenos más a fondo"
                    description="You will save a lot of time going from prototyping to full-functional code because all elements are implemented."
                    action={{ type: "internal", route: "/company/about-us", label: "Ver" }}
                  />
                </RotatingCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <RotatingCard>
                  <RotatingCardFront
                    image={office1}
                    icon="touch_app"
                    title={<>FAQ</>}
                    description="Todas las preguntas y respuestas que frecuentemente nos hacen."
                  />
                  <RotatingCardBack
                    image={office1}
                    title="Ver FAQs"
                    description="Busca entre nuestra serie de preguntas frecuentes o sientete libre de contactar con nosotros si quieres saber más"
                    action={{ type: "internal", route: "/faq", label: "Ver" }}
                  />
                </RotatingCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    title="¿Porqué Kinos?"
                    description="Kinos es una firma de gestión de inversiones y servicios profesionales que ofrece servicios inmobiliarios especializados a clientes que buscan un mayor valor al poseer, ocupar e invertir en bienes raíces. Aplicamos las herramientas de la ciencia de datos moderna a los bienes raíces comerciales y residenciales. Durante décadas, hemos analizado los fundamentos del mercado con coherencia en todas las geografías y tipos de propiedades en España. Nuestros datos, análisis y puntos de vista macroeconómicos brindan información a medida que los clientes evalúan los riesgos y oportunidades que los cambios del mercado pueden representar para sus carteras de inversión."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    title="Nuestro objetivo"
                    description="Kinos busca identificar oportunidades de inversión relacionadas con el sector inmobiliario en España y Europa. Buscamos generar rentabilidades atractivas ajustadas al riesgo con potencial alcista a través de la revalorización o reposicionamiento del capital, con un enfoque particular en las principales áreas metropolitanas de España y los mercados superregionales en Europa."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <RotatingCard>
                  <RotatingCardFront
                    image={office1}
                    icon="touch_app"
                    title={
                      <>
                        Consulta nuestra
                        <br />
                        Demanda de activos
                      </>
                    }
                    description="Actualmente tenemos una serie de demanda de activos para nuetros clientes."
                  />
                  <RotatingCardBack
                    image={office1}
                    title="Demanda de activos"
                    description="Consulta nuestro actual listado de operaciones de demanda."
                    action={{
                      type: "internal",
                      route: "/pages/landing-pages/demand",
                      label: "Ver",
                    }}
                  />
                </RotatingCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <RotatingCard>
                  <RotatingCardFront
                    image={office1}
                    icon="touch_app"
                    title={
                      <>
                        Descubre nuestros
                        <br />
                        servicios
                      </>
                    }
                    description="Tenemos un amplio abanico de servicios relacionados con el sector."
                  />
                  <RotatingCardBack
                    image={office1}
                    title="Descubre todo en lo que podemos ayudarte"
                    description="No solo nos dedicamos a la compraventa de inmuebles, ofrecemos todo un conjunto de servicios a particulares y empresas."
                    action={{ type: "internal", route: "/company/services", label: "Ver" }}
                  />
                </RotatingCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    title="Lo que hacemos"
                    description="Kinos brinda servicios a usuarios, propietarios, inquilinos, inversionistas y desarrolladores de bienes raíces comerciales y residenciales; incluyen corretaje, marketing y ventas, consultoría, servicios a oficinas familiares, servicios de inversión, representación de propietarios e inquilinos, gestión de proyectos, planificación urbana, gestión de propiedades y activos, valoración y servicios de asesoramiento."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
