/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/
import { useState } from "react";
import axios from "axios";
import Switch from "@mui/material/Switch";
import MKSnackbar from "components/MKSnackbar";
// import PropTypes from "prop-types";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// HLink
import { HashLink } from "react-router-hash-link";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
// Images
import bgImage from "assets/images/examples/blog2.jpg";

function Contact() {
  const [isSent, setIsSent] = useState(false);
  const [formControls, setFormControls] = useState({
    sender_name: "",
    sender_phone: "",
    sender_mail: "",
    message: "",
    subject: `I want information`,
    website: `Kinos.es ES - ABOUT US`,
    zone: "",
  });

  const [show, setShow] = useState(false);
  const [accept, setAccept] = useState(true);

  function toggleSnackbar(e) {
    e.preventDefault();
    setShow(!show);
  }

  function toggleAccept() {
    // e.preventDefault();
    setAccept(!accept);
  }

  function onChange(e) {
    const { name } = e.target;
    const { value } = e.target;
    setFormControls({
      ...formControls,
      [name]: value,
    });
  }

  function onSubmit(e) {
    e.preventDefault();
    // get our form data out of state
    const url = `${process.env.REACT_APP_API_URL}/messages/contact`;
    axios.post(url, { formControls }).then((result) => {
      setIsSent(true);
      setShow(true);
      console.log(result); // eslint-disable-line no-console
    });
  }

  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }} id="contact">
      <Container>
        <Grid
          container
          item
          xs={12}
          md={9}
          justifyContent="center"
          sx={{ mx: "auto", mb: 8, textAlign: "center" }}
        >
          <MKTypography variant="h2" mb={1}>
            ¿Quiere vender su propiedad?
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Divida los detalles sobre su propiedad o interés en partes. Escriba unas líneas sobre
            cada uno y contáctenos para más colaboración. Nos comunicaremos con usted en unas horas.
            Kinos & Partners le ofrece un servicio personalizado y la capacidad de poder asesorar a
            clientes cualificados para comprar una propiedad de gama alta. Nuestro objetivo es
            identificar el tipo de cliente idóneo y ofrecerle su propiedad con una presentación
            adecuada y que genere el mayor interés para el comprador. Al contratar los servicios de
            Kinos & Partners para vender su propiedad tendrá acceso inmediato a la red global de más
            de 7000 agencias y profesionales cualificados a nivel nacional e internacional.
            Proporcionamos una amplia gama de herramientas de marketing para las propiedades de
            nuestra cartera de inmuebles. Si está pensando en vender su propiedad estaríamos
            encantados de ayudarle. Póngase en contacto con nosotros y nuestros asesores
            inmobiliarios gestionarán la puesta en el mercado de su propiedad a la mayor brevedad y
            en las mejores condiciones posibles.
          </MKTypography>
        </Grid>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Información de contacto
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Rellena el formulario de contacto y nos pondremos en contacto contigo lo antes
                      posible.
                    </MKTypography>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        (+34) 952 260 231
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        info@kinos.es
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        C/ Simón Bolivar, 17, Blq 1 Local 24 CP 29011 Málaga
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={3}>
                      <MKButton variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                      <MKButton variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-twitter" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                      <MKButton variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-dribbble" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                      <MKButton variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-instagram" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Contáctanos!
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      Estaremos encantados de ayudarte.
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Mi nombre es"
                          placeholder="Nombre completo"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="sender_name"
                          value={formControls.sender_name}
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Mi teléfono es"
                          placeholder="+00 000 000"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="sender_phone"
                          value={formControls.sender_phone}
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Mi correo es"
                          placeholder="correo@tld"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="sender_mail"
                          value={formControls.sender_mail}
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Qué busco"
                          placeholder="Deseo información sobre"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="message"
                          value={formControls.message}
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <FormControl fullWidth variant="standard" sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-label">Especialidad</InputLabel>
                          <Select
                            value="{formControls.speciality}"
                            // onChange={onChange}
                            inputProps={{
                              name: "speciality",
                              id: "speciality",
                            }}
                          >
                            <MenuItem disabled value="Especialidad">
                              Especialidad
                            </MenuItem>
                            <MenuItem value="Broker">Soy un broker</MenuItem>
                            <MenuItem value="Investor">Soy un inversor</MenuItem>
                            <MenuItem value="Seller">Soy un vendedor</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Tu mensaje"
                          placeholder="Mensaje..."
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={6}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sx={{ mb: 3, ml: -1 }}>
                        <Switch
                          id="flexSwitchCheckDefault"
                          defaultChecked
                          onChange={() => toggleAccept()}
                        />
                        <MKTypography
                          component="label"
                          variant="button"
                          color="text"
                          fontWeight="regular"
                          htmlFor="flexSwitchCheckDefault"
                          sx={{ userSelect: "none", cursor: "pointer" }}
                        >
                          Acepto los{" "}
                          <MKTypography
                            component="a"
                            href="#"
                            variant="button"
                            fontWeight="regular"
                          >
                            <u>Términos y condiciones</u>
                          </MKTypography>
                          .
                        </MKTypography>
                      </Grid>
                      <Grid item xs={12}>
                        {isSent ? (
                          <MKButton
                            type="submit"
                            variant="gradient"
                            color="dark"
                            fullWidth
                            disabled
                          >
                            Mensaje enviado
                          </MKButton>
                        ) : (
                          <MKButton
                            type="submit"
                            variant="gradient"
                            color="dark"
                            onClick={(e) => onSubmit(e)}
                            fullWidth
                            disabled={!accept}
                          >
                            Enviar mensaje
                          </MKButton>
                        )}
                      </Grid>
                    </Grid>
                    <MKSnackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      color="dark"
                      icon="notifications"
                      title="Kinos & Partners"
                      content="El mensaje se ha enviado correctamente"
                      dateTime="1 mins ago"
                      open={show}
                      close={(e) => toggleSnackbar(e)}
                    />
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={9}
          justifyContent="center"
          sx={{ mx: "auto", mb: 8, textAlign: "center" }}
        >
          <MKTypography variant="h2" mb={1}>
            ¿O tienes alguna consulta específica?
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Accede a través de nuestros otros formularios de contacto.
          </MKTypography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={9}
          justifyContent="center"
          sx={{ mx: "auto", mb: 8, textAlign: "center" }}
        >
          <HashLink to="/company/work-offers#contact" smooth>
            <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              Quiero trabajar con vosotros
            </MKButton>
          </HashLink>
          <HashLink to="/company/about-us#contact" smooth>
            <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              Tengo otra consulta
            </MKButton>
          </HashLink>
          <HashLink to="/company/buy#contact" smooth>
            <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              Quiero comprar
            </MKButton>
          </HashLink>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
