/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Kcomponents footer and navbar
import DefaultNavbar from "Kcomponents/Navbars/DefaultNavbar";
import DefaultFooter from "Kcomponents/Footers/DefaultFooter";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components
import FaqCollapse from "pages/Support/Faq/components/FaqCollapse";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.torusred.com",
          label: "Torus",
          color: "info",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container sx={{ mb: 8 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <SimpleInfoCard
                icon="description"
                title="Nuestras guías"
                description="The Arctic Ocean freezes every winter and much of the sea-ice then thaws every summer, and that process will continue whatever."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SimpleInfoCard
                icon="support"
                title="Soporte personalizado"
                description="The time is now for it to be okay to be great. People in this world shun people for being great. I'm not trying, I'm not trying to do the best way."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SimpleInfoCard
                icon="grid_view"
                title="Contactamos contigo"
                description="If you have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s passed."
                direction="center"
              />
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    FAQ
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Última actualización: Sept 07 2021
                  </MKTypography>
                </MKBox>
                <MKBox p={6}>
                  <MKTypography variant="h5" my={3}>
                    Básicas
                  </MKTypography>
                  <FaqCollapse
                    title="¿Qué nos diferencia?"
                    open={collapse === 1}
                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                  >
                    We&apos;re not always in the position that we want to be at. We&apos;re
                    constantly growing. We&apos;re constantly making mistakes. We&apos;re constantly
                    trying to express ourselves and actualize our dreams. If you have the
                    opportunity to play this game of life you need to appreciate every moment. A lot
                    of people don&apos;t appreciate the moment until it&apos;s passed.
                  </FaqCollapse>
                  <FaqCollapse
                    title="¿Cómo funcionamos?"
                    open={collapse === 2}
                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                  >
                    It really matters and then like it really doesn&apos;t matter. What matters is
                    the people who are sparked by it. And the people who are like offended by it, it
                    doesn&apos;t matter. Because it&apos;s about motivating the doers. Because
                    I&apos;m here to follow my dreams and inspire other people to follow their
                    dreams, too. We&apos;re not always in the position that we want to be at.
                    We&apos;re constantly growing. We&apos;re constantly making mistakes. We&apos;re
                    constantly trying to express ourselves and actualize our dreams. If you have the
                    opportunity to play this game of life you need to appreciate every moment. A lot
                    of people don&apos;t appreciate the moment until it&apos;s passed.
                  </FaqCollapse>
                  <FaqCollapse
                    title="¿Cuánto tiempo tardais en contestar una duda?"
                    open={collapse === 3}
                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                  >
                    The time is now for it to be okay to be great. People in this world shun people
                    for being great. For being a bright color. For standing out. But the time is now
                    to be okay to be the greatest you. Would you believe in what you believe in, if
                    you were the only one who believed it? If everything I did failed - which it
                    doesn&apos;t, it actually succeeds - just the fact that I&apos;m willing to fail
                    is an inspiration. People are so scared to lose that they don&apos;t even try.
                    Like, one thing people can&apos;t say is that I&apos;m not trying, and I&apos;m
                    not trying my hardest, and I&apos;m not trying to do the best way I know how.
                  </FaqCollapse>
                  <FaqCollapse
                    title="¿Qué servicios ofreceis?"
                    open={collapse === 4}
                    onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                  >
                    I always felt like I could do anything. That&apos;s the main thing people are
                    controlled by! Thoughts- their perception of themselves! They&apos;re slowed
                    down by their perception of themselves. If you&apos;re taught you can&apos;t do
                    anything, you won&apos;t do anything. I was taught I could do everything.
                    <br />
                    <br />
                    If everything I did failed - which it doesn&apos;t, it actually succeeds - just
                    the fact that I&apos;m willing to fail is an inspiration. People are so scared
                    to lose that they don&apos;t even try. Like, one thing people can&apos;t say is
                    that I&apos;m not trying, and I&apos;m not trying my hardest, and I&apos;m not
                    trying to do the best way I know how.
                  </FaqCollapse>
                  <FaqCollapse
                    title="¿Donde puedo encontrar más información?"
                    open={collapse === 5}
                    onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
                  >
                    There&apos;s nothing I really wanted to do in life that I wasn&apos;t able to
                    get good at. That&apos;s my skill. I&apos;m not really specifically talented at
                    anything except for the ability to learn. That&apos;s what I do. That&apos;s
                    what I&apos;m here for. Don&apos;t be afraid to be wrong because you can&apos;t
                    learn anything from a compliment. I always felt like I could do anything.
                    That&apos;s the main thing people are controlled by! Thoughts- their perception
                    of themselves! They&apos;re slowed down by their perception of themselves. If
                    you&apos;re taught you can&apos;t do anything, you won&apos;t do anything. I was
                    taught I could do everything.
                  </FaqCollapse>
                  <FaqCollapse
                    title="¿Qué ventajas tiene utilizar una inmobiliaria online?"
                    open={collapse === 6}
                    onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
                  >
                    Society has put up so many boundaries, so many limitations on what&apos;s right
                    and wrong that it&apos;s almost impossible to get a pure thought out. It&apos;s
                    like a little kid, a little boy, looking at colors, and no one told him what
                    colors are good, before somebody tells you you shouldn&apos;t like pink because
                    that&apos;s for girls, or you&apos;d instantly become a gay two-year-old.
                  </FaqCollapse>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Compra venta y alquiler
                  </MKTypography>
                  <FaqCollapse
                    title="¿Cúal es el Proceso de compra de una casa en España y cómo funciona?"
                    open={collapse === 6}
                    onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
                  >
                    El proceso de compra de una casa en España consiste en los siguientes pasos:
                    <MKBox component="ol" my={3} ml={6}>
                      <MKBox component="li">
                        Búsqueda de propiedades: Puedes buscar propiedades en internet, a través de
                        un agente inmobiliario o de manera directa con el vendedor.
                      </MKBox>
                      <MKBox component="li">
                        Oferta: Una vez que hayas encontrado una propiedad que te guste, puedes
                        hacer una oferta al vendedor o al agente inmobiliario.
                      </MKBox>
                      <MKBox component="li">
                        Negociación y acuerdo: Si la oferta es aceptada, se llega a un acuerdo sobre
                        el precio y las condiciones de la venta.
                      </MKBox>
                      <MKBox component="li">
                        Obtención de un NIE: Si eres extranjero, necesitarás obtener un Número de
                        Identificación de Extranjero (NIE) antes de continuar con el proceso de
                        compra.
                      </MKBox>
                      <MKBox component="li">
                        Contrato de compraventa: Una vez que se haya llegado a un acuerdo, se
                        redacta un contrato de compraventa que incluye los términos y condiciones de
                        la venta.
                      </MKBox>
                      <MKBox component="li">
                        Firma de la escritura: La escritura es el documento legal que formaliza la
                        compraventa de la propiedad y debe ser firmada ante un notario.
                      </MKBox>
                      <MKBox component="li">
                        Pago de impuestos y tributos: Debes pagar los impuestos y tributos
                        correspondientes a la compra, incluyendo el Impuesto sobre Transmisiones
                        Patrimoniales y Actos Jurídicos Documentados (ITP/AJD), el Impuesto sobre
                        Bienes Inmuebles (IBI), la Plusvalía Municipal, los impuestos sobre el
                        registro de la propiedad, los honorarios de abogado y las tasas notariales.
                      </MKBox>
                    </MKBox>
                    Es importante tener en cuenta que el proceso de compra de una casa en España
                    puede ser complejo y requiere la asistencia de un abogado o asesor especializado
                    en inmuebles para asegurarse de que todo se haga correctamente.
                  </FaqCollapse>
                  <FaqCollapse
                    title="¿Qué documentación necesito para comprar una casa en España como extranjero?"
                    open={collapse === 7}
                    onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
                  >
                    Como extranjero, necesitarás presentar los siguientes documentos para comprar
                    una casa en España:
                    <MKBox component="ol" my={3} ml={6}>
                      <MKBox component="li">Número de Identificación de Extranjero (NIE)</MKBox>
                      <MKBox component="li">Pasaporte válido</MKBox>
                      <MKBox component="li">
                        Certificado de residencia en España (si es aplicable)
                      </MKBox>
                      <MKBox component="li">
                        Comprobante de ingresos y fuentes de financiamiento para la compra
                      </MKBox>
                      <MKBox component="li">
                        Documentación sobre la propiedad, incluyendo el título de propiedad y
                        cualquier otro documento relevante
                      </MKBox>
                    </MKBox>
                    Es posible que se requieran documentos adicionales dependiendo de la situación
                    individual y la propiedad en cuestión. Es recomendable consultar con un abogado
                    o asesor especializado en inmuebles en España para obtener más información.
                  </FaqCollapse>
                  <FaqCollapse
                    title="¿Cuáles son los impuestos y tributos a pagar en la compra de una casa en España?"
                    open={collapse === 8}
                    onClick={() => (collapse === 8 ? setCollapse(false) : setCollapse(8))}
                  >
                    Los impuestos y tributos a pagar en la compra de una casa en España incluyen:
                    <MKBox component="ol" my={3} ml={6}>
                      <MKBox component="li">
                        Impuesto sobre Transmisiones Patrimoniales y Actos Jurídicos Documentados
                        (ITP/AJD): Este impuesto varía según la comunidad autónoma y se aplica sobre
                        el valor de compraventa de la propiedad.
                      </MKBox>
                      <MKBox component="li">
                        Impuesto sobre Bienes Inmuebles (IBI): Este impuesto anual es un tributo
                        local sobre la propiedad de un inmueble.
                      </MKBox>
                      <MKBox component="li">
                        Plusvalía Municipal: Es un impuesto sobre el aumento del valor de la tierra
                        que se paga al ayuntamiento.
                      </MKBox>
                      <MKBox component="li">
                        Impuestos sobre el registro de la propiedad: Estos impuestos incluyen el
                        Impuesto de Actos Jurídicos Documentados y el Impuesto de Registro.
                      </MKBox>
                      <MKBox component="li">
                        Honorarios de abogado y tasas notariales: Estos costos incluyen los
                        honorarios de un abogado y los costos de la escritura notarial.
                      </MKBox>
                    </MKBox>
                    Es importante tener en cuenta que estos impuestos y tributos pueden variar en
                    función de la comunidad autónoma y la situación individual, y es recomendable
                    consultar con un asesor fiscal o abogado para obtener una estimación precisa de
                    los costos.
                  </FaqCollapse>
                  <FaqCollapse
                    title="¿Qué es el NIE y por qué es necesario para comprar una propiedad en España?"
                    open={collapse === 9}
                    onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
                  >
                    El Número de Identificación de Extranjero (NIE) es un número único asignado a
                    extranjeros que desean residir, trabajar o realizar cualquier tipo de actividad
                    económica en España.
                    <br />
                    Es necesario para comprar una propiedad en España porque se utiliza como
                    identificación fiscal y para el registro de la propiedad en el Registro de la
                    Propiedad.
                    <br />
                    Sin un NIE, no es posible completar la compra de una propiedad en España, ya que
                    se requiere para la firma de la escritura y para el pago de los impuestos y
                    tributos correspondientes.
                    <br />
                    Por lo tanto, <b>obtener un NIE es un paso crucial </b>
                    en el proceso de compra de una propiedad en España para extranjeros.
                  </FaqCollapse>
                  <FaqCollapse
                    title="¿Qué protección tengo como comprador si algo sale mal con la propiedad o el vendedor?"
                    open={collapse === 9}
                    onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
                  >
                    Como comprador de una propiedad en España, tienes ciertas protecciones legales
                    en caso de problemas con la propiedad o el vendedor. Estas incluyen:
                    <MKBox component="ol" my={3} ml={6}>
                      <MKBox component="li">
                        Garantía de título: El vendedor está obligado a transferir la propiedad
                        libre de cualquier gravamen o problema legal, y a garantizar que la
                        propiedad está registrada correctamente. Si el vendedor incumple esta
                        garantía, el comprador puede reclamar.
                      </MKBox>
                      <MKBox component="li">
                        Garantía de calidad: El vendedor también está obligado a garantizar que la
                        propiedad está en buen estado y cumplirá sus funciones. Si la propiedad se
                        encuentra en mal estado o no cumple sus funciones, el comprador puede
                        reclamar.
                      </MKBox>
                      <MKBox component="li">
                        Protección contra fraude: En caso de que el vendedor proporcione información
                        falsa o oculte información relevante sobre la propiedad, el comprador puede
                        reclamar.
                      </MKBox>
                      <MKBox component="li">
                        Protección contra defectos ocultos: Si la propiedad tiene defectos ocultos
                        que no se revelaron antes de la compra, el comprador puede reclamar.
                      </MKBox>
                    </MKBox>
                    Es importante tener en cuenta que el sistema legal en España puede ser complejo
                    y que es recomendable contar con un abogado especializado en inmuebles para
                    ayudar a proteger tus derechos y resolver cualquier problema. También es
                    recomendable obtener una inspección previa a la compra para identificar
                    cualquier problema o defecto en la propiedad antes de completar la compra.
                  </FaqCollapse>
                  <FaqCollapse
                    title="¿Es seguro invertir en el mercado inmobiliario español?"
                    open={collapse === 9}
                    onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
                  >
                    Invertir en el mercado inmobiliario español puede ser una opción segura si se
                    toman las precauciones adecuadas y se investiga bien antes de hacer una compra.
                    Sin embargo, como con cualquier inversión, hay riesgos asociados, y los
                    compradores deben estar al tanto de ellos.
                    <br />
                    Aquí hay algunos factores que deben tenerse en cuenta al evaluar la seguridad de
                    una inversión en el mercado inmobiliario español:
                    <MKBox component="ol" my={3} ml={6}>
                      <MKBox component="li">
                        Tasa de interés: La tasa de interés puede afectar la demanda de viviendas y,
                        por lo tanto, los precios de las propiedades.
                      </MKBox>
                      <MKBox component="li">
                        Desarrollo económico: El desarrollo económico y la salud financiera del país
                        pueden afectar la demanda de viviendas y los precios.
                      </MKBox>
                      <MKBox component="li">
                        Oferta y demanda: El equilibrio entre la oferta y la demanda de viviendas
                        puede afectar los precios y la estabilidad del mercado inmobiliario.
                      </MKBox>
                      <MKBox component="li">
                        Regulaciones gubernamentales: Las regulaciones gubernamentales, como los
                        impuestos y las leyes de vivienda, pueden afectar el mercado inmobiliario.
                      </MKBox>
                    </MKBox>
                    En resumen, invertir en el mercado inmobiliario español puede ser una opción
                    segura si se toman las precauciones adecuadas, se investiga bien y se consideran
                    todos los factores relevantes. Sin embargo, como con cualquier inversión,
                    siempre existe un cierto nivel de riesgo.
                  </FaqCollapse>
                  <FaqCollapse
                    title="¿Qué consideraciones debo tener en cuenta antes de comprar una casa en España?"
                    open={collapse === 9}
                    onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
                  >
                    Antes de comprar una casa en España, hay varias consideraciones importantes que
                    debes tener en cuenta para asegurarte de que la compra sea una experiencia
                    satisfactoria y sin problemas:
                    <MKBox component="ol" my={3} ml={6}>
                      <MKBox component="li">
                        Presupuesto: Establece un presupuesto realista y asegúrate de que puedes
                        permitirte el costo total de la compra, incluyendo los impuestos, los gastos
                        legales y los gastos de mantenimiento.
                      </MKBox>
                      <MKBox component="li">
                        Ubicación: Considera cuidadosamente la ubicación de la propiedad, incluyendo
                        la distancia a las tiendas, la escuela y otras instalaciones importantes.
                      </MKBox>
                      <MKBox component="li">
                        Estilo de vida: Piensa en tus necesidades y preferencias, como el tamaño de
                        la casa, el número de habitaciones, la presencia de un jardín y otros
                        detalles importantes.
                      </MKBox>
                      <MKBox component="li">
                        Regulaciones gubernamentales: Investiga las regulaciones gubernamentales
                        relevantes, como los impuestos sobre la propiedad, las leyes de vivienda y
                        las regulaciones de construcción.
                      </MKBox>
                      <MKBox component="li">
                        Estado de la propiedad: Asegúrate de que la propiedad esté en buen estado y
                        no haya problemas ocultos. Es recomendable obtener una inspección previa a
                        la compra para identificar cualquier problema o defecto en la propiedad.
                      </MKBox>
                      <MKBox component="li">
                        Asesoría legal: Contrata a un abogado especializado en inmuebles que pueda
                        ayudarte a entender y cumplir con todas las leyes y regulaciones relevantes.
                      </MKBox>
                      <MKBox component="li">
                        Seguro: Asegúrate de que la propiedad esté asegurada contra posibles
                        problemas, como daños por incendios, inundaciones y otros riesgos.
                      </MKBox>
                    </MKBox>
                    En resumen, considera cuidadosamente estos factores y haz una investigación
                    exhaustiva antes de comprar una casa en España para asegurarte de que estás
                    haciendo una inversión inteligente y segura.
                  </FaqCollapse>
                  <FaqCollapse
                    title="¿Cómo financiar la compra de una propiedad en España como extranjero?"
                    open={collapse === 9}
                    onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
                  >
                    Hay varias opciones para financiar la compra de una propiedad en España como
                    extranjero:
                    <MKBox component="ol" my={3} ml={6}>
                      <MKBox component="li">
                        Préstamos hipotecarios: Puedes solicitar un préstamo hipotecario en un banco
                        local en España.
                        <br />
                        Es importante tener en cuenta que, como extranjero, puede ser más difícil
                        obtener un préstamo hipotecario y que es posible que debas cumplir con
                        ciertos requisitos adicionales.
                      </MKBox>
                      <MKBox component="li">
                        Financiamiento privado: Puedes buscar financiamiento privado a través de
                        amigos o familiares que estén dispuestos a prestarte el dinero.
                      </MKBox>
                      <MKBox component="li">
                        Inversiones de terceros: Puedes considerar la opción de invertir en una
                        propiedad en sociedad con otras personas, lo que te permitirá compartir los
                        costos y reducir el riesgo.
                      </MKBox>
                      <MKBox component="li">
                        Ahorros personales: Puedes utilizar tus ahorros personales para financiar la
                        compra de la propiedad.
                      </MKBox>
                    </MKBox>
                    En cualquier caso, es importante asegurarse de que tienes una fuente de ingresos
                    estable y que puedes cumplir con las obligaciones financieras antes de decidir
                    financiar la compra de una propiedad en España. Es recomendable consultar con un
                    asesor financiero o un abogado especializado en inmuebles para obtener más
                    información sobre las opciones de financiamiento disponibles.
                  </FaqCollapse>
                  <FaqCollapse
                    title="¿Qué considerar al comprar una casa en Marbella?"
                    open={collapse === 9}
                    onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
                  >
                    Hay varias puntos que deberías analizar al comprar una propiedad en Marbella:
                    <MKBox component="ol" my={3} ml={6}>
                      <MKBox component="li">
                        Averigua que tal es de segura la zona.
                        <br />
                        Es importante tener en cuenta que, aunque Marbella es una zona generalmente
                        muy segura, esto podrá variar según la zona.
                      </MKBox>
                      <MKBox component="li">Analiza los costos de propiedad asociados. </MKBox>
                      <MKBox component="li">
                        Infórmate sobre la distancia a la playa y lugares turísticos.
                      </MKBox>
                      <MKBox component="li">
                        ¿Cuál es la calidad de las vistas y la privacidad de la propiedad?
                      </MKBox>
                      <MKBox component="li">
                        Averigua si hay algún problema de ruido o contaminación en la zona
                      </MKBox>
                      <MKBox component="li">
                        ¿Cómo está mantenida la propiedad y cuáles son los costos de mantenimiento?
                      </MKBox>
                      <MKBox component="li">
                        ¿Cuáles son las leyes y regulaciones locales relacionadas con la propiedad y
                        el uso del suelo?
                      </MKBox>
                      <MKBox component="li">
                        ¿Hay algún problema de desarrollo o construcción en la zona que pueda
                        afectar el valor de la propiedad en el futuro?
                      </MKBox>
                      <MKBox component="li">
                        ¿Hay una comunidad fuerte y activa en el área, o es un lugar más tranquilo y
                        retirado?
                      </MKBox>
                      <MKBox component="li">
                        ¿Cuáles son las leyes y regulaciones locales relacionadas con la propiedad y
                        el uso del suelo?
                      </MKBox>
                    </MKBox>
                    Es importante considerar estas preguntas y hacer una investigación exhaustiva
                    antes de tomar una decisión sobre la compra de una casa en Marbella. También es
                    recomendable consultar con un agente inmobiliario local o un abogado
                    especializado en inmuebles para obtener más información y asesoramiento sobre el
                    proceso de compra.
                  </FaqCollapse>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={{ xs: 0, lg: 3 }} pb={3}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Faq;
