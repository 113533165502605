/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import { Link } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Kcomponents footer and navbar
import DefaultNavbar from "Kcomponents/Navbars/DefaultNavbar";
import DefaultFooter from "Kcomponents/Footers/DefaultFooter";

// Home page sections
import Information from "pages/LandingPages/Home/sections/Information";
// import AboutUs from "pages/LandingPages/Home/sections/AboutUs";
// import Residential from "pages/LandingPages/Home/sections/Residential";
// import NewDevelopment from "pages/LandingPages/Demand/sections/NewDevelopment";
import Assets from "pages/LandingPages/Demand/sections/Assets";
import Contact from "pages/LandingPages/Home/sections/Contact";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/Kinos/bg-home.jpg";

function Home() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "http://www.torusred.com",
          label: "Torus",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "start" }}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Demanda actual de activos
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              mt={1}
              pr={{ md: 12, lg: 24, xl: 32 }}
              opacity={0.8}
            >
              Kinos es una gran fuente de captación de oportunidades de inversión y de negocio así
              como un gran marketplace para encontrar inversores.
            </MKTypography>
            <Stack direction="row" spacing={1} mt={6} mb={3}>
              <MKButton
                variant="gradient"
                color="info"
                component={Link}
                to="/pages/landing-pages/new-development"
              >
                Obra Nueva
              </MKButton>
              <MKButton
                variant="gradient"
                color="info"
                component={Link}
                to="/pages/landing-pages/residential"
              >
                Residencial
              </MKButton>
              <MKButton
                variant="gradient"
                color="info"
                component={Link}
                to="/pages/landing-pages/rental"
              >
                Alquileres
              </MKButton>
            </Stack>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Assets />
        <Contact />
        <Information />
        <Container>
          <MKBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="xl"
            my={24}
            p={6}
            sx={{
              backgroundImage:
                "url(https://images.unsplash.com/photo-1533563906091-fdfdffc3e3c4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80)",
            }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={8} lg={5}>
                <MKTypography variant="h5" color="white" fontWeight="bold">
                  Permanezca informado y consulte nuestros arículos y guías en nuestro BLOG.
                </MKTypography>
              </Grid>
              <Grid item xs={12} lg={6} sx={{ ml: "auto" }}>
                <MKBox width="12rem" ml="auto">
                  <MKButton variant="gradient" color="warning" fullWidth sx={{ boxShadow: "none" }}>
                    Noticias
                  </MKButton>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Container>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
