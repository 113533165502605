/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import { useState, useEffect } from "react";
import consola from "consola";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import SimpleAssetCard from "Kcomponents/Cards/AssetCards/SimpleAssetCard";

function Residential() {
  const [listData, setListData] = useState([]);

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/viviendas?per_page=6`;
    url = `${url}&compra=true`;

    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        const myData = data.data; // .map((el) => el.attributes);
        setListData(myData);
      });
  }, []);

  consola.log(listData);

  function renderAssets(assets) {
    if (typeof assets !== "undefined" && assets.length > 0) {
      return assets.map((asset) => (
        <Grid item xs={12} md={6} lg={4} key={asset.attributes.ref}>
          <MKBox mt={3}>
            <SimpleAssetCard
              image={
                asset.attributes.images.length > 0
                  ? asset.attributes.images[0].attributes.adjunto_medium
                  : "NO SE HA SUBIDO IMAGENES"
              }
              title={asset.attributes.name}
              description={asset.attributes.short_description}
              id={asset.attributes.ref}
              baths={asset.attributes.bannos}
              bedrooms={asset.attributes.dormitorios}
              price={asset.attributes.precio}
              action={{
                type: "internal",
                route: `/residential/asset/${asset.id}`,
                color: "info",
                label: "Ir",
              }}
              categories={asset.attributes.caracteristicas}
              rent={asset.attributes.to_rent}
              sale={asset.attributes.to_sell}
            />
          </MKBox>
        </Grid>
      ));
    }
    return (
      <Grid container spacing={6} alignItems="center" justifyContent="center" direction="column">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h5>No assets yet</h5>
        </Grid>
      </Grid>
    );
  }

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKTypography variant="h2" mb={1}>
            Buscar entre nuestras propiedades en residencial{" "}
          </MKTypography>
          <MKTypography variant="body2" color="text">
            ¿Buscas una propiedad? ¡Has llegado al lugar correcto! Nuestro buscador de propiedades
            inmobiliarias es la herramienta perfecta para encontrar la casa o el apartamento de tus
            sueños. Con nuestro buscador, puedes filtrar por ubicación, tipo de propiedad, número de
            habitaciones y mucho más. Además, nuestra base de datos se actualiza constantemente para
            asegurarnos de ofrecerte las propiedades más recientes en el mercado. Y si tienes algo
            en particular en mente, no dudes en ponerte en contacto con nosotros. Nuestro equipo de
            profesionales altamente cualificados estará encantado de ayudarte a encontrar la
            propiedad perfecta para ti. ¡No dudes en comenzar tu búsqueda hoy mismo!
          </MKTypography>
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="RESIDENCIAL"
            container
            circular
            sx={{ mb: 1 }}
          />
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {renderAssets(listData)}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Residential;
