/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import ResidentialAsset from "layouts/pages/assets/residential";
import NewDevelopmentAsset from "layouts/pages/assets/new-development";
import DemandAsset from "layouts/pages/assets/demand";
import PostPage from "pages/Blogs/PostPage";
import TaggedNews from "layouts/pages/blogs/tagged-news";
// Material Kit 2 PRO React routes
import routes from "routes";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route
          path="/residential/asset/:id"
          key="ResidentialAsset"
          element={<ResidentialAsset />}
        />
        <Route path="/demand/asset/:id" key="DemandAsset" element={<DemandAsset />} />
        <Route
          path="/new-development/asset/:id"
          key="NewDevelopmentAsset"
          element={<NewDevelopmentAsset />}
        />
        <Route path="/news/post/:portal/:id" key="PostPage" element={<PostPage />} />
        <Route path="/blogs/news/tagged/:tag" key="TaggedNews" element={<TaggedNews />} />
        <Route path="/presentation" key="Presentation" element={<Presentation />} />
        <Route
          path="/en"
          component={() => {
            window.location.href = "https://es.kinos.es";
            return null;
          }}
        />
        <Route path="*" element={<Navigate to="/" />} key="RootPath" />
      </Routes>
    </ThemeProvider>
  );
}
