/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultNavbar from "Kcomponents/Navbars/DefaultNavbar";

// Author page sections
import Profile from "pages/Blogs/PostPage/sections/Profile";
import Posts from "pages/Blogs/PostPage/sections/Posts";
import Contact from "pages/Blogs/PostPage/sections/Contact";
// import Footer from "pages/Blogs/PostPage/sections/Footer";
import DefaultFooter from "Kcomponents/Footers/DefaultFooter";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
// Images
// import bgImage from "assets/images/city-profile.jpg";

function Post() {
  const [newsData, setNewsData] = useState([]);
  const { id, portal } = useParams();
  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/news/${id}?portal=${portal}`;
    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        const myData = data.data.attributes; // .map((el) => el.attributes);
        setNewsData(myData);
      });
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://torusred.com",
          label: "Torus",
          color: "info",
        }}
        sticky
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(https://wsrv.nl?url=${newsData.adjunto_large})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Profile newsData={newsData} id={id} />
          <Posts />
        </Card>
        <Contact />
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Post;
