/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import { useState, useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
// Material Kit 2 PRO React examples
import DefaultBlogCard from "examples/Cards/BlogCards/DefaultBlogCard";

// Images
import author1 from "assets/images/Kinos/kinos-redaccion.jpg";

function Posts() {
  // const actionProps = {
  //   type: "internal",
  //   route: "/residential/asset",
  //   color: "dark",
  //   label: "find more",
  // };

  const [listData, setListData] = useState([]);
  const [perPage, setPerPage] = useState(12);
  const [total, setTotal] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/news?per_page=${perPage}&portal=28`;
    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        const myData = data.data; // .map((el) => el.attributes);
        setListData(myData);
        setTotal(data.meta.total);
      });
  }, [perPage]);

  function onMore() {
    setPerPage(perPage + 6);
  }

  function renderAssets(assets) {
    if (typeof assets !== "undefined" && assets.length > 0) {
      return assets.map((asset) => (
        <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
          <MKBox mt={3}>
            <DefaultBlogCard
              image={`https://wsrv.nl?url=${asset.attributes.adjunto_medium}`}
              category={{ color: "primary", label: "Life Style" }}
              title={asset.attributes.title}
              description={asset.attributes.leadin}
              // action={actionProps}
              author={{
                image: author1,
                name: "Redacción Kinos",
                date: asset.attributes.fecha_publicacion,
              }}
              action={{ type: "internal", route: `/news/post/28/${asset.id}` }}
            />
          </MKBox>
        </Grid>
      ));
    }
    return (
      <Grid container spacing={6} alignItems="center" justifyContent="center" direction="column">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h5>No news yet</h5>
        </Grid>
      </Grid>
    );
  }

  return (
    <MKBox component="section" py={7}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={9} textAlign="center" mx="auto">
            <MKTypography variant="h3" mb={0.5}>
              Life Style
            </MKTypography>
            <MKTypography variant="body2" color="text" px={{ xs: 0, md: 6 }} mb={4}>
              Create a unique and beautiful blog posts. You can also connect your blog directly to
              Google Analytics to have a more detailed look.
            </MKTypography>
            {perPage < total ? (
              <MKTypography variant="h3" mb={0.5}>
                Mostrando {perPage} de un total de {total} activos
              </MKTypography>
            ) : (
              <MKTypography variant="h3" mb={0.5}>
                Mostrando un total de {total} resultados
              </MKTypography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {renderAssets(listData)}
        </Grid>
        <Grid container>
          <Grid item xs={12} spacing={10} textAlign="center" mx="auto">
            {perPage < total ? (
              <>
                <MKTypography variant="h3" mb={0.5}>
                  Mostrando {perPage} de un total de {total} noticias
                </MKTypography>
                <MKButton variant="gradient" color="info" onClick={() => onMore()}>
                  Ver más...
                </MKButton>
              </>
            ) : (
              <MKTypography variant="h3" mb={0.5}>
                Mostrando un total de {total} resultados
              </MKTypography>
            )}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Posts;
