/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/team-5.jpg";
import team2 from "assets/images/bruce-mars.jpg";
import team3 from "assets/images/ivana-squares.jpg";
import team4 from "assets/images/ivana-square.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Actualmente buscamos los siguientes perfiles
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Si crees que puedes encajar en alguno de ellos ponte en contacto con nosotros.
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Somos una consultora inmobiliaria y empresarial con desarrollo de plataforma
              tecnológica propia pero con desarrollo de trabajo basado en personas y relaciones a
              largo plazo. Nuestra misión es asegurar que nuestra organización cuenta con el mejor
              talento para dar el mejor servicio a nuestros clientes. Contáctanos, nos gustaría
              conocer y escuchar tu historia. Podemos desarrollar todo tu potencial y desarrollo de
              beneficios en común. Nuestro objetivo es que las personas crezcan y se desarrollen
              profesionalmente. Constantemente estamos abriendo oportunidades de desarrollo de
              trabajo y negocios.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Asesor de inversiones Málaga zona este"
                position={{ color: "info", label: "Asesor senior" }}
                description="Buscamos un asesor de inversiones con amplia experiencia para la zona este de la provincia de Málaga."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Abogados"
                position={{ color: "info", label: "Abogados" }}
                description="Con experiencia en Operaciones Inmobiliarias Extranjería, Urbanismo"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Analista Financiero"
                position={{ color: "info", label: "Analista financiero" }}
                description="Estudios de viabilidad de proyectos inmobiliarios. Control de presupuestos Gestión de operaciones financieras. Gestión y tramitación de hipotecas."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Diseñador Gráfico y web"
                position={{ color: "info", label: "Diseñador Gráfico y web" }}
                description="Diseño de Teaser de venta inmobiliarios. Preparación de ofertas comerciales. Diseño  y mejoras de campañas comerciales"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Analista de Datos"
                position={{ color: "info", label: "Analista de Datos" }}
                description="Análisis y recopilación de datos.Preparación de gráficos para propuestas comerciales. Diseño  y mejoras de campañas comerciales basadas en datos."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Asesores/ Broker  Inmobiliarios"
                position={{ color: "info", label: "Asesores/ Broker  Inmobiliarios" }}
                description="Agentes para Costa del Sol. Zonas Marbella, Estepona, Sotogrande. Málaga Capital "
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
