/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import PropTypes from "prop-types";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Stack from "@mui/material/Stack";
// import MKTypography from "components/MKTypography";
// Material Kit 2 PRO React components

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MKTypography from "components/MKTypography";

import MKBox from "components/MKBox";
// HLink
import { HashLink } from "react-router-hash-link";
// Material Kit 2 PRO React components
import MKButton from "components/MKButton";

import { styled } from "@mui/material/styles";

import Gallery from "./Gallery";
import DataM from "./DataM";
import DataS from "./DataS";
import DataE from "./DataE";
import Tags from "./Tags";
// import "./gallery.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Info({ images, data, id }) {
  // console.log("IMAGES ARR:", imgArr); // eslint-disable-line no-console
  return (
    <MKBox component="section" pb={{ xs: 0, md: 0 }} pt={{ xs: 10, md: 10 }} mb={0}>
      <Container>
        <Grid container item xs={12} justifyContent="center">
          <Gallery images={images} />
          {data.n_viviendas === 0 && <DataE data={data} id={id} />}
          {data.n_viviendas === 1 && <DataS data={data} id={id} />}
          {data.n_viviendas > 1 && <DataM data={data} id={id} />}
        </Grid>
        <Grid container item xs={12} justifyContent="left">
          <Tags tags={data.tags} />
        </Grid>
        {data.n_viviendas > 1 && (
          <Grid container item xs={12} md={12} lg={12} justifyContent="left">
            <Grid container item xs={12} justifyContent="left">
              <MKTypography variant="h5" sx={{ lineHeight: 3 }}>
                Viviendas disponibles:
              </MKTypography>
            </Grid>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="viviendas table">
                <TableHead sx={{ display: "table-header-group" }}>
                  <TableRow key="table_head">
                    <TableCell style={{ ml: 100 }}>Ref</TableCell>
                    <TableCell align="right">Precio</TableCell>
                    <TableCell align="right">Dormitorios</TableCell>
                    <TableCell align="right">Baños</TableCell>
                    <TableCell align="right">Superficie&nbsp;</TableCell>
                    <TableCell align="right">Información</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.viviendas.map((v) => (
                    <StyledTableRow
                      key={v.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row" style={{ pl: 100 }}>
                        {v.id}
                      </StyledTableCell>
                      <StyledTableCell align="right">{v.attributes.precio} </StyledTableCell>
                      <StyledTableCell align="right">{v.attributes.dormitorios} </StyledTableCell>
                      <StyledTableCell align="right">{v.attributes.bannos} </StyledTableCell>
                      <StyledTableCell align="right">
                        {v.attributes.superficie}&nbsp;&nbsp;
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <HashLink to="#contact" smooth>
                          <MKButton variant="contained" size="small" color="info" key="info">
                            Información
                          </MKButton>
                        </HashLink>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Container>
    </MKBox>
  );
}
//
// localidad: {
// id: 3553,
// name: "Málaga",
// seoname: "malaga"
// },
// exterior: true,
// alquiler: null,
// fecha_construccion: "Ask",
// modalidad: {
// id: 1,
// name: "Venta"
// },
// cert_energetica: "Ask",
// metros_playa: "Ask",

// Setting default props for the Info
Info.defaultProps = {
  images: [],
  data: null,
  id: null,
};

// Typechecking props for the Info
Info.propTypes = {
  images: PropTypes.any,
  data: PropTypes.object,
  id: PropTypes.string,
};

export default Info;
