/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/
/* eslint-disable import/no-unresolved */

import { useRef, useState, useEffect } from "react";

// SwiperJS
import { Autoplay, Navigation } from "swiper/modules";

// SwiperJS react components
import { Swiper, SwiperSlide } from "swiper/react";

// SwiperJS styles
import "swiper/css";
import "swiper/css/navigation";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Steps() {
  // install SwiperJS modules
  // SwiperCore.use([Autoplay, Navigation]);

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [swiperEl, setSwiperEl] = useState(null);

  const slideTo = (index) => swiperEl && swiperEl.slideTo(index);

  const [listData, setListData] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/news?per_page=4`;
    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        const myData = data.data.map((el) => el.attributes);
        setListData(myData);
      });
  }, []);

  // Swiper navigation buttons styles
  const navigationStyles = {
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",

    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  return (
    <MKBox component="section" py={12} position="relative">
      <Grid container mt={16}>
        <Grid item xs={12} lg={12} textAlign="center">
          <MKTypography variant="h3" mb={0.5}>
            Noticias Destacadas de obra nueva
          </MKTypography>
        </Grid>
      </Grid>
      <Container>
        <Grid container mt={16}>
          {listData.map(({ id, title }, index) => (
            <Grid key={title} item xs={6} lg={3} textAlign="center">
              <MKTypography
                variant="body1"
                color="success"
                fontWeight="bold"
                pl={2}
                textGradient
                sx={{ fontFamily: ({ typography: { h2 } }) => h2.fontFamily, cursor: "pointer" }}
                onClick={() => slideTo(index + 1)}
              >
                <MKBox component="span">{id}</MKBox>
                <MKBox component="span" ml={1}>
                  {title}
                </MKBox>
              </MKTypography>
            </Grid>
          ))}
        </Grid>
        <Swiper
          modules={[Navigation, Autoplay]}
          onInit={(swiper) => {
            setSwiperEl(swiper);

            const { navigation: nav } = swiper.params;
            const { navigation } = swiper;

            nav.prevEl = navigationPrevRef.current;
            nav.nextEl = navigationNextRef.current;
            navigation.init();
            navigation.update();
          }}
          autoplay={{ delay: 5000 }}
          speed={800}
          spaceBetween={0}
          slidesPerView={1}
          allowTouchMove={false}
          loop
        >
          {listData.map(({ adjunto, title, leadin, description }) => (
            <SwiperSlide key={title}>
              <Grid container spacing={3} alignItems="center" sx={{ mb: { xs: 6, md: 0 } }}>
                <Grid item xs={12} md={5} ml={{ xs: 0, lg: "auto" }}>
                  <MKBox p={2}>
                    <MKBox
                      component="img"
                      src={`https://wsrv.nl/?url=${adjunto}`}
                      alt={adjunto}
                      width="100%"
                      borderRadius="xl"
                      maxHeight="37.5rem"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={5} mr={{ xs: 0, lg: "auto" }} position="relative">
                  <MKTypography
                    component="h6"
                    variant="button"
                    opacity={0.7}
                    textTransform="uppercase"
                    fontWeight="bold"
                  >
                    {title}
                  </MKTypography>
                  <MKTypography variant="h5" fontWeight="bold">
                    {leadin}
                  </MKTypography>
                  <MKTypography variant="body1" my={3}>
                    {description}
                  </MKTypography>
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
          <MKBox
            display="flex"
            position="absolute"
            bottom={{ xs: 0, lg: "10%" }}
            right="5%"
            zIndex={2}
          >
            <MKTypography
              variant="h2"
              ref={navigationPrevRef}
              color="dark"
              mr={{ xs: 8, md: 2, lg: 8 }}
              sx={navigationStyles}
            >
              <MKBox className="fas fa-chevron-left" />
            </MKTypography>
            <MKTypography variant="h2" ref={navigationNextRef} color="dark" sx={navigationStyles}>
              <MKBox className="fas fa-chevron-right" />
            </MKTypography>
          </MKBox>
        </Swiper>
      </Container>
    </MKBox>
  );
}

export default Steps;
