/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import React from "react";
import { Link } from "react-router-dom";
// import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
// @mui material components
// import Container from "@mui/material/Container";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import MKBadge from "components/MKBadge";

// Material Kit 2 PRO React components
// import MKBox from "components/MKBox";

function Social({ tags }) {
  return (
    <Grid container>
      <Grid item xs={12} lg={12}>
        <MKTypography
          variant="body1"
          fontWeight="light"
          color="info"
          mt={3}
          sx={{
            width: "max-content",
            display: "flex",
            alignItems: "center",

            "& .material-icons-round": {
              transform: `translateX(3px)`,
              transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
            },

            "&:hover .material-icons-round, &:focus .material-icons-round": {
              transform: `translateX(6px)`,
            },
          }}
        >
          Tags:
        </MKTypography>
        <Stack direction="row" alignItems="flex-end" spacing={0.5}>
          {tags.map((tag) => (
            <MKBadge
              component={Link}
              to={`/blogs/news/tagged/${tag.name}`}
              badgeContent={tag.name}
              color="info"
              container
            />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}

// Setting default props for the Social component
Social.defaultProps = {
  tags: [],
};

// Typechecking props for the Social component
Social.propTypes = {
  tags: PropTypes.array,
};

export default Social;
