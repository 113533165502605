/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import { useState, useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import SimpleAssetCard from "Kcomponents/Cards/AssetCards/SimpleAssetCard";

function NewDevelopment() {
  const [listData, setListData] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/promociones?per_page=6`;
    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        const myData = data.data; // .map((el) => el.attributes);
        setListData(myData);
      });
  }, []);

  function renderAssets(assets) {
    if (typeof assets !== "undefined" && assets.length > 0) {
      return assets.map((asset) => (
        <Grid item xs={12} md={6} lg={4} key={asset.attributes.ref}>
          <MKBox mt={3}>
            <SimpleAssetCard
              image={asset.attributes.images[0].attributes.adjunto_medium}
              title={asset.attributes.name}
              description={asset.attributes.short_description}
              id={asset.attributes.ref}
              price={asset.attributes.price}
              action={{
                type: "internal",
                route: `/new-development/asset/${asset.id}`,
                color: "info",
                label: "Ir",
              }}
              categories={asset.attributes.caracteristicas}
            />
          </MKBox>
        </Grid>
      ));
    }
    return (
      <Grid container spacing={6} alignItems="center" justifyContent="center" direction="column">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h5>No assets yet</h5>
        </Grid>
      </Grid>
    );
  }

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKTypography variant="h2" mb={1}>
            Buscar entre nuestras propiedades de obra nueva{" "}
          </MKTypography>
          <MKTypography variant="body2" color="text">
            ¿Buscas una nueva propiedad? ¡Has llegado al lugar correcto! Nuestro buscador de
            propiedades inmobiliarias es la herramienta perfecta para encontrar la casa de tus
            sueños. Con nuestro buscador, puedes filtrar por ubicación, tipo de propiedad, tamaño y
            presupuesto para encontrar la opción perfecta para ti. Además, nuestra base de datos se
            actualiza diariamente con las últimas propiedades disponibles en el mercado, así que
            siempre estarás al tanto de las últimas opciones. Si tienes alguna duda o necesitas
            ayuda para encontrar la propiedad perfecta, no dudes en ponerte en contacto con nuestro
            equipo de profesionales. Estaremos encantados de ayudarte a encontrar tu hogar ideal.
            ¡Comienza a buscar ahora mismo!
          </MKTypography>
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="OBRA NUEVA"
            container
            circular
            sx={{ mb: 1 }}
          />
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {renderAssets(listData)}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default NewDevelopment;
