/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import LinkInfoCard from "Kcomponents/Cards/InfoCards/LinkInfoCard";

function Information() {
  return (
    <MKBox component="section" py={{ xs: 6, md: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <LinkInfoCard
                icon="payment"
                title="Consulta nuestra actual demanda de activos"
                description="Para que encuetres lo que mejor se adapta a tus necesidades."
              />
              <LinkInfoCard
                icon="insights"
                title="¿Quieres alquilar?"
                description="Trabajamos para que nuestros precios sean los más competitivos del mercado."
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mr: { xs: 0, md: "auto" }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <LinkInfoCard
                icon="access_alarms"
                title="Trabaja con nosotros"
                description="Nuestros agentes están capacitados para darte la información que necesitas y asesorarte en el proceso de compra."
              />
              <LinkInfoCard
                icon="people"
                title="¿Quieres vender o alquilar tu propiedad?"
                description="Consulte sin compromiso con uno de nuestros especialistas."
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
