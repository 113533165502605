/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function AboutUs() {
  const data = [
    {
      icon: "credit_card",
      title: "Intermediación compra, venta y alquiler",
      items: [
        "Servicios de asesoría y transacción",
        "Gestión y administración de propiedades",
        "Venta de propiedades",
      ],
    },
    {
      icon: "credit_card",
      title: "Marketing y venta de obra nueva",
      items: ["Marketing de proyectos", "Comunicaciones", "Atención al cliente"],
    },
    {
      icon: "credit_card",
      title: "Gestion de activos",
      items: ["Planeamiento", "Gestión", "Estrategia de salida"],
    },
    {
      icon: "credit_card",
      title: "Valoración",
      items: ["Valoración de activos", "Estrategias de inversión"],
    },
    {
      icon: "credit_card",
      title: "Búsqueda y prospección",
      items: ["Canales de inversión", "Estudios de mercado"],
    },
    {
      icon: "group",
      title: "Desarrollo urbanístico",
      items: ["Servicios de asesoría y transacciones", "Estudios urbanisticos"],
    },
  ];

  return (
    <MKBox component="section" py={12} px={1}>
      <Container>
        <Grid container item xs={12} lg={8}>
          <MKTypography variant="h3">
            Líder en servicios inmobiliarios y gestión de inversiones.
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="text">
            Kinos proporciona resultados excepcionales para los clientes al combinar conocimientos
            del mercado local, amplios servicios, experiencia especializada y herramientas y
            recursos tecnológicos de primer nivel. Brindamos asesoramiento y servicios de expertos
            para maximizar el valor de la propiedad para los ocupantes, propietarios e inversores de
            bienes raíces.
          </MKTypography>
        </Grid>
        <Grid container sx={{ mt: 6 }}>
          {data.map(({ icon, title, items }) => (
            <Grid key={icon} item xs={12} md={4}>
              <MKBox py={2} pr={2}>
                <MKTypography variant="h3" color="info">
                  <Icon>{icon}</Icon>
                </MKTypography>
                <MKTypography variant="h5" mt={2} mb={3}>
                  {title}
                </MKTypography>
                {items.map((item) => (
                  <MKBox key={item} display="flex" lineHeight={1.25}>
                    <MKTypography variant="body1" color="info">
                      <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                    </MKTypography>
                    <MKBox pl={2}>
                      <MKTypography variant="button" color="text" fontWeight="bold">
                        {item}
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AboutUs;
