/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Kcomponents footer and navbar
import DefaultNavbar from "Kcomponents/Navbars/DefaultNavbar";
import DefaultFooter from "Kcomponents/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function Privacy() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "http://www.torusred.com",
          label: "Torus",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Términos y Condiciones de uso. Política legal
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Última modificación: Sept 10 2022
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h4" mt={6} mb={3}>
                    IDENTIDAD Y DETALLES DE CONTACTO
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    <MKTypography variant="h5" mt={6} mb={3}>
                      1. Información legal y aceptación
                    </MKTypography>
                    En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios
                    de la Sociedad de la Información y Comercio Electrónico, a continuación se
                    exponen los datos identificativos de la empresa. El presente portal de internet
                    se pone al servicio de los usuarios por la entidad, cuyos datos identificativos
                    son:
                    <MKTypography variant="h6" mt={6} mb={3}>
                      Kinos &amp; Partners S.L
                      <br />
                      Domicilio: Avenida Simón Bolivar 17 local 24 CP 29011 Málaga, Spain
                      <br />
                      Tel.: +34 952260510
                      <br />
                      +34952260231
                      <br />
                      E-mail: info@kinosgroup.com
                      <br />
                      Número de identificación fiscal: B93143618
                      <br />
                      Registro Mercantil de Málaga Spain
                      <br />
                    </MKTypography>
                    El presente aviso legal recoge las condiciones generales que rigen el acceso y
                    el uso de este sitio web, en adelante “el sitio web”. El uso del sitio web
                    implica la expresa y plena aceptación de estas condiciones generales en la
                    versión publicada en el momento en que el usuario acceda al mismo, sin perjuicio
                    de las condiciones particulares que pudieran aplicarse a algunos de los
                    servicios concretos del sitio web.
                    <br />
                    El acceso a la página web es gratuito salvo en lo relativo al coste de la
                    conexión a través de la red de telecomunicaciones suministrada por el proveedor
                    de acceso contratado por los usuarios. Determinados servicios son exclusivos
                    para nuestros clientes y su acceso se encuentra restringido.
                    <br />
                    La utilización del Portal atribuye la condición de usuario del Portal (en
                    adelante, el &#39;Usuario&#39;) e implica la aceptación de todas las condiciones
                    incluidas en este Aviso Legal. La prestación del servicio del Portal tiene una
                    duración limitada al momento en el que el Usuario se encuentre conectado al
                    Portal o a alguno de los servicios que a través del mismo se facilitan. Por
                    tanto, el Usuario debe leer atentamente el presente Aviso Legal en cada una de
                    las ocasiones en que se proponga utilizar el Portal, ya que éste y sus
                    condiciones de uso recogidas en el presente Aviso Legal pueden sufrir
                    modificaciones.
                    <br />
                    Algunos servicios del Portal accesibles para los usuarios de internet o
                    exclusivos para los clientes de KINOS &amp; PARTNERS S.L, pueden estar sometidos
                    a condiciones particulares, reglamentos e instrucciones que, en su caso,
                    sustituyen, completan y/o modifican el presente Aviso Legal y que deberán ser
                    aceptadas por el Usuario antes de iniciarse la prestación del servicio
                    correspondiente.
                    <MKTypography variant="h5" mt={6} mb={3}>
                      2. Propiedad intelectual e industrial
                    </MKTypography>
                    Este sitio web y los contenidos que alberga se encuentran protegidos por la
                    legislación vigente en materia de propiedad intelectual.
                    <br />
                    KINOS &amp; PARTNERS S.L es titular o licenciatario de todos los derechos de
                    propiedad intelectual e industrial de su web, así como de los elementos
                    contenidos en la misma. Por ello, queda expresamente prohibida la reproducción,
                    distribución, comunicación pública y transformación de la totalidad o parte de
                    los contenidos de esta web, con fines comerciales, en cualquier soporte y por
                    cualquier medio técnico, sin la autorización de Términos y Condiciones.
                    <br />
                    Las marcas, nombres comerciales o signos distintivos son titularidad de KINOS
                    &amp; PARTNERS S.L ya sea por propiedad industrial o intelectual, sin que pueda
                    entenderse que el acceso al Portal atribuya ningún derecho sobre las citadas
                    marcas, nombres comerciales y/o signos distintivos.
                    <br />
                    Todos los productos y servicios de estas páginas que NO son propiedad de KINOS
                    &amp; PARTNERS S.L son marcas registradas de sus respectivos propietarios y son
                    reconocidas como tales por nuestra empresa. Solamente aparecen en la web de
                    KINOS &amp; PARTNERS S.L a efectos de promoción y de recopilación de
                    información. Estos propietarios pueden solicitar la modificación o eliminación
                    de la información que les pertenece.
                    <MKTypography variant="h5" mt={6} mb={3}>
                      3. Condiciones de uso del portal
                    </MKTypography>
                    <MKTypography variant="h6" mt={6} mb={3}>
                      3.1 General
                    </MKTypography>
                    <br />
                    El Usuario se obliga a hacer un uso correcto del Portal de conformidad con la
                    Ley y el presente Aviso Legal. El Usuario responderá frente a KINOS &amp;
                    PARTNERS S.L o frente a terceros, de cualesquiera daños y perjuicios que
                    pudieran causarse como consecuencia del incumplimiento de dicha obligación.
                    <br />
                    Queda expresamente prohibido el uso del Portal con fines lesivos de bienes o
                    intereses de KINOS &amp; PARTNERS S.L o de terceros o que de cualquier otra
                    forma sobrecarguen, dañen o inutilicen las redes, servidores y demás equipos
                    informáticos (hardware) o productos y aplicaciones informáticas (software) de
                    KINOS &amp; PARTNERS S.L o de terceros.
                    <MKTypography variant="h6" mt={6} mb={3}>
                      3.2 Contenidos
                    </MKTypography>
                    El Usuario se compromete a utilizar los Contenidos de conformidad con la Ley y
                    el presente Aviso Legal, así como con las demás condiciones, reglamentos e
                    instrucciones que en su caso pudieran ser de aplicación de conformidad con lo
                    dispuesto en la cláusula 1. Con carácter meramente enunciativo, el Usuario de
                    acuerdo con la legislación vigente deberá abstenerse de:
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Reproducir, copiar, distribuir, poner a disposición, comunicar
                          públicamente, transformar o modificar los Contenidos salvo en los casos
                          autorizados en la ley o expresamente consentidos por KINOS &amp; PARTNERS
                          S.L o por quien ostente la titularidad de los derechos de explotación en
                          su caso.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Reproducir o copiar para uso privado los Contenidos que puedan ser
                          considerados como Software o Base de Datos de conformidad con la
                          legislación vigente en materia de propiedad intelectual, así como su
                          comunicación pública o puesta a disposición de terceros cuando estos actos
                          impliquen necesariamente la reproducción por parte del Usuario o de un
                          tercero.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Extraer y/o reutilizar la totalidad o una parte sustancial de los
                          Contenidos integrantes del Portal así como de las bases de datos que KINOS
                          &amp; PARTNERS S.L ponga a disposición de los Usuarios.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    <MKTypography variant="h6" mt={6} mb={3}>
                      3.3 Introducción de enlaces al Portal
                    </MKTypography>
                    El usuario de Internet que quiera introducir enlaces desde sus propias páginas
                    web al Portal deberá cumplir con las condiciones que se detallan a continuación
                    sin que el desconocimiento de las mismas evite las responsabilidades derivadas
                    de la Ley:
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          El enlace únicamente vinculará con la home page o página principal del
                          Portal pero no podrá reproducirla de ninguna forma (online links, copia de
                          los textos, gráficos, etc).
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Quedará en todo caso prohibido, de acuerdo con la legislación aplicable y
                          vigente en cada momento, establecer frames o marcos de cualquier tipo que
                          envuelvan al Portal o permitan la visualización de los Contenidos a través
                          de direcciones de Internet distintas a las del Portal y, en cualquier
                          caso, cuando se visualicen conjuntamente con contenidos ajenos al Portal
                          de forma que:
                          <br />
                          (I) produzca, o pueda producir, error, confusión o engaño en los usuarios
                          sobre la verdadera procedencia del servicio o Contenidos;
                          <br />
                          (II) suponga un acto de comparación o imitación desleal;
                          <br />
                          (III) sirva para aprovechar la reputación de la marca y prestigio de KINOS
                          &amp; PARTNERS S.L o
                          <br />
                          (IV) de cualquier otra forma resulte prohibido por la legislación vigente.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          No se realizarán desde la página que introduce el enlace ningún tipo de
                          manifestación falsa, inexacta o incorrecta sobre KINOS &amp; PARTNERS S.L,
                          sus socios, empleados, clientes o sobre la calidad de los servicios que
                          presta.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          En ningún caso, se expresará en la página donde se ubique el enlace que
                          KINOS &amp; PARTNERS S.L ha prestado su consentimiento para la inserción
                          del enlace o que de otra forma patrocina, colabora, verifica o supervisa
                          los servicios del remitente.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Está prohibida la utilización de cualquier marca denominativa, gráfica o
                          mixta o cualquier otro signo distintivo de KINOS &amp; PARTNERS S.L dentro
                          de la página del remitente salvo en los casos permitidos por la ley o
                          expresamente autorizados por KINOS &amp; PARTNERS S.L y siempre que se
                          permita, en estos casos, un enlace directo con el Portal en la forma
                          establecida en esta cláusula.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          La página que establezca el enlace deberá cumplir fielmente con la ley y
                          no podrá en ningún caso disponer o enlazar con contenidos propios o de
                          terceros que:
                          <br />
                          (I) sean ilícitos, nocivos o contrarios a la moral y a las buenas
                          costumbres (pornográficos, violentos, racistas, etc.);
                          <br />
                          (II) induzcan o puedan inducir en el Usuario la falsa concepción de que
                          KINOS &amp; PARTNERS S.L suscribe, respalda, se adhiere o de cualquier
                          manera apoya, las ideas, manifestaciones o expresiones, lícitas o
                          ilícitas, del remitente;
                          <br />
                          (III) resulten inapropiados o no pertinentes con la actividad de KINOS
                          &amp; PARTNERS S.L en atención al lugar, contenidos y temática de la
                          página web del remitente.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    <MKTypography variant="h5" mt={6} mb={3}>
                      4. Exclusión de responsabilidad
                    </MKTypography>
                    <MKTypography variant="h6" mt={6} mb={3}>
                      4.1 De la Información
                    </MKTypography>
                    KINOS &amp; PARTNERS S.L no se responsabiliza de las decisiones tomadas a partir
                    de la información suministrada en el Portal ni de los daños y perjuicios
                    producidos en el Usuario o terceros con motivo de actuaciones que tengan como
                    único fundamento la información obtenida en el Portal.
                    <MKTypography variant="h6" mt={6} mb={3}>
                      4.2 De la calidad del servicio
                    </MKTypography>
                    El acceso al Portal no implica la obligación por parte de KINOS &amp; PARTNERS
                    S.L de controlar la ausencia de virus, gusanos o cualquier otro elemento
                    informático dañino. Corresponde al Usuario, en todo caso, la disponibilidad de
                    herramientas adecuadas para la detección y desinfección de programas
                    informáticos dañinos. KINOS &amp; PARTNERS S.L no se responsabiliza de los daños
                    producidos en los equipos informáticos de los Usuarios o de terceros durante la
                    prestación del servicio del Portal.
                    <MKTypography variant="h6" mt={6} mb={3}>
                      4.3 De la disponibilidad del Servicio
                    </MKTypography>
                    El acceso al Portal requiere de servicios y suministros de terceros, incluidos
                    el transporte a través de redes de telecomunicaciones cuya fiabilidad, calidad,
                    continuidad y funcionamiento no corresponde a KINOS &amp; PARTNERS S.L Por
                    consiguiente, los servicios proveídos a través del Portal pueden ser
                    suspendidos, cancelados o resultar inaccesibles, con carácter previo o
                    simultáneo a la prestación del servicio del Portal.
                    <br />
                    KINOS &amp; PARTNERS S.L no se responsabiliza de los daños o perjuicios de
                    cualquier tipo producidos en el Usuario que traigan causa de fallos o
                    desconexiones en las redes de telecomunicaciones que produzcan la suspensión,
                    cancelación o interrupción del servicio del Portal durante la prestación del
                    mismo o con carácter previo.
                    <MKTypography variant="h6" mt={6} mb={3}>
                      4.4 De los contenidos y servicios enlazados a través del Portal
                    </MKTypography>
                    El servicio de acceso al Portal incluye dispositivos técnicos de enlace,
                    directorios e incluso instrumentos de búsqueda que permiten al Usuario accede a
                    otras páginas y portales de Internet (en adelante, &#39;Sitios Enlazados&#39;).
                    En estos casos, KINOS &amp; PARTNERS S.L actúa como prestador de servicios de
                    intermediación de conformidad con el artículo 17 de la Ley 34/2002, de 12 de
                    julio, de Servicios de la Sociedad de la Información y el Comercio Electrónico
                    (LSSI) y sólo será responsable de los contenidos y servicios suministrados en
                    los Sitios Enlazados en la medida en que tenga conocimiento efectivo de la
                    ilicitud y no haya desactivado el enlace con la diligencia debida. En el
                    supuesto de que el Usuario considere que existe un Sitio Enlazado con contenidos
                    ilícitos o inadecuados podrá comunicárselo a KINOS &amp; PARTNERS S.L de acuerdo
                    con el procedimiento y los efectos establecidos en la cláusula 6, sin que en
                    ningún caso esta comunicación conlleve la obligación de retirar el
                    correspondiente enlace.
                    <br />
                    En ningún caso, la existencia de Sitios Enlazados debe presuponer la existencia
                    de acuerdos con los responsables o titulares de los mismos, ni la recomendación,
                    promoción o identificación de KINOS &amp; PARTNERS S.L con las manifestaciones,
                    contenidos o servicios proveídos. KINOS &amp; PARTNERS S.L no conoce los
                    contenidos y servicios de los Sitios Enlazados y por tanto no se hace
                    responsable por los daños producidos por la ilicitud, calidad, desactualización,
                    indisponibilidad, error e inutilidad de los contenidos y/o servicios de los
                    Sitios Enlazados ni por cualquier otro daño que no sea directamente imputable a
                    KINOS &amp; PARTNERS S.L.
                    <MKTypography variant="h5" mt={6} mb={3}>
                      5. Protección de datos.
                    </MKTypography>
                    Puede consultar nuestra política de Protección de Datos (enlazar) para conocer
                    el uso que hacemos de sus datos personales.
                    <MKTypography variant="h5" mt={6} mb={3}>
                      6. Comunicación de actividades de carácter ilícito e inadecuado
                    </MKTypography>
                    En el caso de que el Usuario o cualquier otro usuario de Internet tuvieran
                    conocimiento de que los Sitios Enlazados remiten a páginas cuyos contenidos o
                    servicios son ilícitos, nocivos, denigrantes, violentos o contrarios a la moral
                    podrá ponerse en contacto con KINOS &amp; PARTNERS S.L indicando los siguientes
                    extremos:
                    <MKBox component="ol" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Datos personales del comunicante: nombre, dirección, número de teléfono y
                          dirección de correo electrónico;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Descripción de los hechos que revelan el carácter ilícito o inadecuado del
                          Sitio Enlazado;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          En el supuesto de violación de derechos, tales como propiedad intelectual
                          e industrial, los datos personales del titular del derecho infringido
                          cuando sea persona distinta del comunicante.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Asimismo deberá aportar el título que acredite la legitimación del titular
                          de los derechos y, en su caso, el de representación para actuar por cuenta
                          del titular cuando sea persona distinta del comunicante;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Declaración expresa de que la información contenida en la reclamación es
                          exacta. La recepción por parte de KINOS &amp; PARTNERS S.L de la
                          comunicación prevista en esta cláusula no supondrá, según lo dispuesto en
                          la LSSI, el conocimiento efectivo de las actividades y/o contenidos
                          indicados por el comunicante.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    Asimismo deberá aportar el título que acredite la legitimación del titular de
                    los derechos y, en su caso, el de representación para actuar por cuenta del
                    titular cuando sea persona distinta del comunicante.
                    <MKTypography variant="h5" mt={6} mb={3}>
                      7. Notificaciones
                    </MKTypography>
                    Todas las notificaciones y comunicaciones realizadas por las partes serán
                    válidas de conformidad con los medios admitidos en Derecho. Las relativas al
                    presente Portal se considerarán eficaces, a todos los efectos, si se realizan a
                    través del propio Portal.
                    <MKTypography variant="h5" mt={6} mb={3}>
                      8. Modificaciones
                    </MKTypography>
                    KINOS &amp; PARTNERS S.L se reserva el derecho de efectuar sin previo aviso las
                    modificaciones que considere oportunas en su web, pudiendo cambiar, suprimir o
                    añadir tanto los contenidos y servicios que se presten a través de la misma como
                    la forma en la que éstos aparezcan presentados.
                    <br />
                    Por otro lado, los presentes términos y condiciones podrán cambiar en cualquier
                    momento. Las modificaciones entrarán en vigor desde el momento de su
                    publicación.
                    <MKTypography variant="h5" mt={6} mb={3}>
                      9. Redes Sociales
                    </MKTypography>
                    KINOS &amp; PARTNERS S.L está presente en diversas redes sociales y plataformas,
                    por lo tanto, cuando se accede a cualquiera de estas u otras redes, habrá que
                    tener en cuenta que las condiciones de uso aplicables son las que cada
                    plataforma tiene establecidas, en las cuales, KINOS &amp; PARTNERS S.L no
                    interviene.
                    <MKTypography variant="h5" mt={6} mb={3}>
                      10. Legislación
                    </MKTypography>
                    El presente Aviso Legal se rige en todos y cada uno de sus extremos por la ley
                    española Siempre y cuando la Ley aplicable permita la renuncia al fuero legal
                    establecido, se designa como fuero para dirimir posibles disputas, los juzgados
                    de Málaga, renunciando expresamente el usuario a cualquier otro fuero que
                    pudiera corresponder.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
