import { useState, useEffect } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
// import Switch from "@mui/material/Switch";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

function SimpleModal({ total, formControls, setFormControls, setReload }) {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const [localidadesData, setLocalidadesData] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/localidades/promociones`;
    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        const myData = data; // .map((el) => el.attributes);
        setLocalidadesData(myData);
      });
  }, []);

  function setFilter(e) {
    const { name } = e.target;
    const { value } = e.target;
    setFormControls({
      ...formControls,
      [name]: value,
    });
    setReload(true);
  }

  function renderLocalidades() {
    return localidadesData.map((localidad) => (
      <MenuItem value={localidad.id}>{localidad.municipio}</MenuItem>
    ));
  }

  return (
    <Container>
      <MKButton variant="gradient" color="info" onClick={toggleModal}>
        Filtrar
      </MKButton>
      <Modal
        open={show}
        onClose={toggleModal}
        sx={{ overflow: "scroll", display: "grid", placeItems: "center" }}
      >
        <Slide direction="down" in={show} timeout={500}>
          <MKBox
            position="relative"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
            sx={{ width: { xs: "400px", md: "600px", lg: "800px" } }}
          >
            <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Formulario de filtrado</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <div>
                    {total === 0 ? (
                      <div>
                        <h2>
                          No hay resultados. Contactenos por email o cambie su criterio de búsqueda.
                        </h2>
                      </div>
                    ) : (
                      <h2>Resultados del filtro: {total} promociones</h2>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <form>
                    <MKInput
                      variant="standard"
                      id="search"
                      name="search"
                      label="Palabra clave"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      value={formControls.search}
                      onChange={(e) => setFilter(e)}
                    />
                    <MKInput
                      variant="standard"
                      label="Precio mínimo"
                      id="min_price"
                      name="min_price"
                      value={formControls.min_price}
                      onChange={(e) => setFilter(e)}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                    <MKInput
                      id="sup_min"
                      name="sup_min"
                      variant="standard"
                      label="Superficie mínima"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      value={formControls.sup_min}
                      onChange={(e) => setFilter(e)}
                    />
                    <br />
                    <br />
                    <FormControl fullWidth>
                      <InputLabel id="tipologia_id-label">Tipología</InputLabel>
                      <Select
                        variant="standard"
                        labelId="tipologia-label"
                        id="tipologia"
                        name="tipologia"
                        value={formControls.tipologia}
                        // label="localidad"
                        onChange={(e) => setFilter(e)}
                      >
                        <MenuItem value="">Todas</MenuItem>
                        <MenuItem value="2">Apartamento/Piso</MenuItem>
                        <MenuItem value="10">Villa</MenuItem>
                        <MenuItem value="15">Adosado</MenuItem>
                        <MenuItem value="3">Ático</MenuItem>
                        <MenuItem value="5">Bajo</MenuItem>
                        <MenuItem value="33">Casa Chalet</MenuItem>
                        <MenuItem value="4">Duplex</MenuItem>
                        <MenuItem value="11">Estudio</MenuItem>
                        <MenuItem value="14">Loft</MenuItem>
                        <MenuItem value="12">Pareado</MenuItem>
                        <MenuItem value="6">Garaje</MenuItem>
                        <MenuItem value="18">Suite</MenuItem>
                        {/* 8, Parcela
                            32, Parking Publico
                            9, Trastero */}
                      </Select>
                    </FormControl>
                  </form>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <form>
                    <MKInput
                      id="zona"
                      name="zone"
                      variant="standard"
                      label="Zona"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      value={formControls.zone}
                      onChange={(e) => setFilter(e)}
                    />
                    <MKInput
                      id="max_price"
                      name="max_price"
                      variant="standard"
                      label="Precio máximo"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      value={formControls.max_price}
                      onChange={(e) => setFilter(e)}
                    />
                    <MKInput
                      id="sup_max"
                      name="sup_max"
                      variant="standard"
                      label="Superficie máxima"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      value={formControls.sup_max}
                      onChange={(e) => setFilter(e)}
                    />
                    <br />
                    <br />
                    <FormControl fullWidth>
                      <InputLabel id="order-by-label">Localidad</InputLabel>
                      <Select
                        variant="standard"
                        labelId="localidad-label"
                        id="localidad"
                        name="localidad"
                        value={formControls.localidad}
                        label="localidad"
                        onChange={(e) => setFilter(e)}
                      >
                        <MenuItem value="">Todas</MenuItem>
                        {renderLocalidades()}
                      </Select>
                    </FormControl>
                  </form>
                </Grid>
              </Grid>
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              {total === 0 ? (
                <Link to="/support/contact-us">
                  <MKButton color="info" size="lg" round>
                    Contactar
                  </MKButton>
                </Link>
              ) : (
                <MKButton variant="gradient" color="info" onClick={toggleModal}>
                  Ver resultados
                </MKButton>
              )}
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>
    </Container>
  );
}

SimpleModal.propTypes = {
  total: PropTypes.number.isRequired,
  formControls: PropTypes.array.isRequired,
  setFormControls: PropTypes.any.isRequired,
  setReload: PropTypes.any.isRequired,
};

export default SimpleModal;
