/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/
import React from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
// import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Description({ description }) {
  return (
    <Container>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <MKTypography variant="h3" my={1}>
            Descripción
          </MKTypography>
          <MKTypography variant="body2" color="text" mb={2}>
            <ReactMarkdown>{description}</ReactMarkdown>
          </MKTypography>
        </Grid>
      </Grid>
    </Container>
  );
}

// Setting default props for the Description
Description.defaultProps = {
  description: "",
};

// Typechecking props for the Description
Description.propTypes = {
  description: PropTypes.string,
};

export default Description;
