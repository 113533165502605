/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import LinkInfoCard from "Kcomponents/Cards/InfoCards/LinkInfoCard";

function Information() {
  return (
    <MKBox component="section" py={{ xs: 6, md: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <LinkInfoCard
                icon="payment"
                title="Quiero comprar"
                description="Seleccionamos las mejores casas  y apartamentos para ti y tu familia. La mayor oferta de casas, villas y apartamentos. Gran red colaborativa de asesores. Dinos que buscas y encontraremos las mejores oportunidades."
                url="/company/buy"
              />
              <LinkInfoCard
                icon="insights"
                title="Demanda de servicios profesionales"
                description="Asesoramiento personalizado, Kinos tiene la mayor red de expertos  asociados para asesorarle, abogados, asesores fiscales y financieros. Sabemos lo importante que es para ti proteger tus inversiones y tomar decisiones inteligentes en el mercado inmobiliario. Te ponemos en contacto con los mejores expertos"
                url="/company/services"
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mr: { xs: 0, md: "auto" }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <LinkInfoCard
                icon="access_alarms"
                title="Quiero vender"
                description="Te ayudamos a hacer realidad la venta al mejor precio. Somos una gran red de profesionales altamente capacitados. Contáctanos hoy mismo para comenzar el proceso de venta de tu propiedad."
                url="/company/sell"
              />
              <LinkInfoCard
                icon="people"
                title="Ofrecer servicios profesionales"
                description="Si eres un buen profesional con experiencia desmostrable en asesoramiento inmobiliario, abogacía, asesoramiento fiscal, financiero, seguros, arquitecto, decorador etc te invitamos a unirte a nuestra red de asociados. Buscamos expertos/as en servicios profesionales que estén comprometidos con la excelencia y la satisfacción del cliente."
                url="/company/services"
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
