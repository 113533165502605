/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

import PropTypes from "prop-types";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import MKDatePicker from "components/MKDatePicker";
// import MKButton from "components/MKButton";
import ModalFilter from "./ModalFilter";

function Search({ total, formControls, setFormControls, setReload }) {
  function setOrder(e) {
    const { name } = e.target;
    const { value } = e.target;
    setFormControls({
      ...formControls,
      [name]: value,
    });
    setReload(true);
  }
  return (
    <MKBox component="section">
      <Container>
        <Grid
          container
          align="center"
          spacing={{ xs: 0, lg: 3 }}
          sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}
        >
          <Grid item xs={12} lg={12} sx={{ mt: 4 }}>
            <ModalFilter
              total={total}
              formControls={formControls}
              setFormControls={setFormControls}
              setReload={setReload}
            />
          </Grid>
          <Grid item xs={12} lg={12} sx={{ mt: 2 }}>
            <MKBox sx={{ minWidth: 120 }}>
              <FormControl sx={{ minWidth: 420, mr: 2, mt: 2 }}>
                <InputLabel id="order-by-label">Ordenar por</InputLabel>
                <Select
                  variant="standard"
                  labelId="order-by-label"
                  id="order_by"
                  name="order_by"
                  value={formControls.order_by}
                  label="Order by"
                  onChange={(e) => setOrder(e)}
                >
                  <MenuItem value="precio">Precio</MenuItem>
                  <MenuItem value="superficie">Superficie</MenuItem>
                  <MenuItem value="published_at">Fecha publicación</MenuItem>
                  <MenuItem value="dormitorios">Habitaciones</MenuItem>
                  <MenuItem value="bannos">Baños</MenuItem>
                  <MenuItem value="metros_playa">Distancia playa</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 420, mt: 2 }}>
                <InputLabel id="order-label">Dirección</InputLabel>
                <Select
                  variant="standard"
                  labelId="order-label"
                  id="order"
                  name="order"
                  value={formControls.order}
                  label="Order"
                  onChange={(e) => setOrder(e)}
                >
                  <MenuItem value="asc">Ascendente</MenuItem>
                  <MenuItem value="desc">Descendente</MenuItem>
                </Select>
              </FormControl>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

Search.propTypes = {
  total: PropTypes.number.isRequired,
  formControls: PropTypes.array.isRequired,
  setFormControls: PropTypes.any.isRequired,
  setReload: PropTypes.any.isRequired,
};

export default Search;
