/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

// Material Kit 2 PRO React pages
import TaggedNews from "pages/Blogs/TaggedNews";

export default function TaggedNewsPage() {
  return <TaggedNews />;
}
