/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

// @mui material components
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Kcomponents footer and navbar
import DefaultNavbar from "Kcomponents/Navbars/DefaultNavbar";
import DefaultFooter from "Kcomponents/Footers/DefaultFooter";

// Pricing page sections
import Header from "pages/Company/Services/sections/Header";
import AboutUs from "pages/Company/Services/sections/AboutUs";
// import ServicesSection from "pages/Company/Services/sections/Services";
// import LifetimeMembership from "pages/Company/Services/sections/LifetimeMembership";
import Testimonials from "pages/Company/Services/sections/Testimonials";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function Pricing() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://torusred.com",
          label: "Torus",
        }}
        sticky
      />
      <Header />
      <MKBox p={3}>
        <AboutUs />
        <Testimonials />
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Pricing;
