/*
=========================================================
* Kinos.es React - v1.0.0
=========================================================

* Product Page: http://www.kinos.es
* Copyright 2021 Kinos & Partners S.L.

Coded by PasfID S.L.

=========================================================
*/

// prop-types is a library for typechecking of props.
import React from "react";
import ReactMarkdown from "react-markdown";
// import ReactDom from "react-dom";
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";

import Tags from "./Tags";

function Profile({ newsData, id }) {
  const uri = `http://www.kinos.es/news/post/${id}`;
  const { title, leadin } = newsData;
  const uriBig = `\r\n\r\nVer artículo completo en: ${uri}`;
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKBox
              component="img"
              src={`https://wsrv.nl?url=${newsData.adjunto_large}`}
              alt={newsData.title}
              width="100%"
              borderRadius="md"
              shadow="lg"
            />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">{newsData.title}</MKTypography>
                <MKButton variant="outlined" color="info" size="small">
                  Guardar
                </MKButton>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text">
                {newsData.leadin}
                <br />
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text">
                <ReactMarkdown>{newsData.content}</ReactMarkdown>
                <br />
              </MKTypography>
              <MKTypography
                component="a"
                href="#"
                variant="body1"
                fontWeight="light"
                color="info"
                mt={3}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",

                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },

                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
                Redacción Kinos
              </MKTypography>
              <Grid container spacing={3} mb={3}>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    323&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Posts
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    3.5k&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Followers
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    260&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Follows
                  </MKTypography>
                </Grid>
              </Grid>
              <Tags tags={newsData.tags} />
              <MKTypography
                variant="body1"
                fontWeight="light"
                color="info"
                mt={3}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",

                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },

                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
                Compartir
              </MKTypography>
              <EmailShareButton url={uriBig} subject={title} body={leadin}>
                <EmailIcon size={32} round />
              </EmailShareButton>
              <FacebookShareButton url={uri} quote={leadin} hashtag="#kinos.es">
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <LinkedinShareButton url={uri} source={uri} title={title} summary={leadin}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <PinterestShareButton url={uri} media={uri} description={leadin}>
                <PinterestIcon size={32} round />
              </PinterestShareButton>
              <PocketShareButton url={uri} title={title}>
                <PocketIcon size={32} round />
              </PocketShareButton>
              <RedditShareButton url={uri} title={title}>
                <RedditIcon size={32} round />
              </RedditShareButton>
              <TelegramShareButton url={uri} title={title}>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              <TumblrShareButton url={uri} title={title}>
                <TumblrIcon size={32} round />
              </TumblrShareButton>
              <TwitterShareButton url={uri} title={title}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton url={uri} title={title}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Setting default props for the Profile
Profile.defaultProps = {
  newsData: null,
  id: null,
};

// Typechecking props for the Profile
Profile.propTypes = {
  newsData: PropTypes.any,
  id: PropTypes.number,
};

export default Profile;
